import IMarketBookmark from "../../apis/MarketBookmark/IMarketBookmark";
import { del, post } from "../../apis/flybyApi";
import IArticleSearchResult from "../home/IArticleSearchResult";

export const addArticleBookmarkOld = async (
  articleId: number
): Promise<{ status: number; data: IMarketBookmark | null }> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = Number(sessionStorage.getItem("customerId"));

  let data = {
    article: {
      id: articleId,
    },
    customer: {
      id: customerId,
    },
  };

  try {
    const response = await fetch("/api/market/bookmarks", {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(data),
    });

    const status = response.status;
    const responseData = status === 204 ? null : await response.json();

    return { status, data: responseData };
  } catch (error) {
    console.log(error);
    return { status: 500, data: null };
  }
};

export const deleteArticleBookmarkOld = async (
  bookmarkId: number
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/bookmarks/" + bookmarkId + "?customer=" + customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "DELETE",
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const addArticleBookmark = async (
  customerId: number,
  data: IMarketBookmark
): Promise<IMarketBookmark> =>
  post<IMarketBookmark>(`/market/bookmarks/?customer=${customerId}`, data);

export const deleteArticleBookmark = async (
  bookmarkId: number,
  customerId: number
): Promise<any> =>
  del<any>(`/market/bookmarks/${bookmarkId}?customer=${customerId}`);

export const deleteArticleBookmarkByArticleId = async (
  articleId: number,
  customerId: number
): Promise<IArticleSearchResult> =>
  del<IArticleSearchResult>(
    `/market/bookmarks/articles/${articleId}?customer=${customerId}`
  );
