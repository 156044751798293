import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Spin,
  Tag,
  Tooltip,
  notification,
} from "antd";
import IArticle from "./IArticle";
import IArticleSearchResult from "./IArticleSearchResult";
import {
  ShoppingCartOutlined,
  StarOutlined,
  getTwoToneColor,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";
import { BasketContext } from "../../Utils/BasketProvider";
import {
  checkArticleAvailability,
  isArticleSearchResult,
  onChangePlatform,
  onChangeQuantity,
} from "./ArticleUtilsFunctional";
import ArticleAvailability2 from "./ArticleAvailability2";
import ArticleImage from "./component/ArticleImage";
import ArticleWishlistForm from "./ArticleWishlistForm";
import MoreAvailabilities from "./component/MoreAvailabilities";
import ArticlePrice from "./component/ArticlePrice";
import ArticleEquivalence2 from "./ArticleEquivalence2";
import IArticleEquivalence from "./IArticleEquivalence";
import { isArticleEquivalence } from "./ArticleEquivalenceUtilsFunctional";
import { getArticleById } from "./ArticleServiceFunctional";
import {
  addArticleBookmark,
  deleteArticleBookmarkByArticleId,
} from "../bookmark/BookmarkServiceFunctional";

interface ArticleCardProps {
  dataLoadingState: boolean;
  item: IArticleSearchResult | IArticleEquivalence;
  isModal: boolean;
  showArticleEquivalence: boolean;
  infosTecdoc?: boolean;
  infosCatalogF1?: boolean;
  equivalenceStyle: boolean;
  onSelectArticle: (articleSearchResult: IArticleSearchResult) => void;
  onSelectEquivalence: (articleId: number) => void;
  openModalAccount: () => void;
  onUpdateBookmarkToItemList: (value: IArticleSearchResult) => void;
}

const ArticleCardNew = ({
  dataLoadingState,
  item,
  isModal,
  infosTecdoc,
  infosCatalogF1,
  showArticleEquivalence,
  equivalenceStyle,
  onSelectArticle,
  onSelectEquivalence,
  openModalAccount,
  onUpdateBookmarkToItemList,
}: ArticleCardProps): React.ReactElement => {
  const customerId = sessionStorage.getItem("customerId");

  const [articleState, setArticleState] = useState<IArticleSearchResult>();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [shoppingCartThumbnailState, setShoppingCartThumbnailState] =
    useState<boolean>(false);

  // const onSelectedArticle = useCallback(
  //   (articleSearchResult: IArticleSearchResult) => {
  //     setSelectedArticleSearchResult(articleSearchResult);
  //     setSelectedArticle(articleSearchResult.article);
  //   },
  //   [setSelectedArticle, setSelectedArticleSearchResult]
  // );

  // const onChangeArticleQuantity = async () => {};

  const onChangeArticleQuantity = async (
    quantity: number,
    platformId: number
  ) => {
    if (articleState) {
      setLoadingState(true);
      try {
        const newItem = await onChangeQuantity(
          quantity,
          platformId,
          articleState.article
        );
        setArticleState({
          ...articleState,
          article: { ...newItem, quantity: quantity },
        });
      } finally {
        setLoadingState(false);
      }
    }
  };

  const onChangeArticlePlatform = async (
    platformId: number,
    currentItem: IArticle
  ) => {
    if (articleState) {
      // const item = {...currentItem}
      setLoadingState(true);
      try {
        const newItem = await onChangePlatform(
          platformId,
          articleState.article
        );
        setArticleState({ ...articleState, article: newItem });
      } finally {
        setLoadingState(false);
      }
    }
  };

  // const searchAvailabilities = useCallback(
  //   async (currentItem: IArticleSearchResult) => {
  //     if (
  //       currentItem.article.articlePlatforms &&
  //       currentItem.article.articlePlatforms.length > 0
  //     ) {
  //       currentItem.article.quantity =
  //         currentItem.article.articlePlatforms[0].salePackaging;

  //       try {
  //         setLoadingState(true);
  //         let newArticle = await getArticleById(currentItem.article.id);
  //         const articleWithAvailabilities = await checkArticleAvailability(
  //           undefined,
  //           currentItem.article
  //         );

  //         if (isArticleSearchResult())
  //           newArticle = {
  //             ...newArticle.content,
  //             article: {
  //               ...currentItem.article,
  //               moreAvailabilitiesShow:
  //                 articleWithAvailabilities.moreAvailabilitiesShow,
  //               listAvailabilities:
  //                 articleWithAvailabilities.listAvailabilities,
  //               availability: articleWithAvailabilities.availability,
  //               moreAvailabilities:
  //                 articleWithAvailabilities.moreAvailabilities,
  //             },
  //           };
  //         setArticleState(newArticle);
  //       } finally {
  //         setLoadingState(false);
  //       }
  //     }
  //   },
  //   []
  // );

  const searchAvailabilities = useCallback(
    async (currentObjet: IArticleEquivalence | IArticleSearchResult) => {
      setLoadingState(true);
      try {
        let response;
        if (isArticleEquivalence(currentObjet)) {
          response = await getArticleById(currentObjet.equivalentArticle.id);
        }
        if (isArticleSearchResult(currentObjet)) {
          response = await getArticleById(currentObjet.article.id);
        }
        //  Si l'article a été trouvé
        if (response !== undefined && response.content.length > 0) {
          let quantity = 1;
          if (response.content[0].article.articlePlatforms) {
            quantity =
              response.content[0].article.articlePlatforms[0].salePackaging;
          }
          response.content[0].article.quantity = quantity;

          if (
            response.content[0].article.articlePlatforms &&
            response.content[0].article.articlePlatforms.length > 0
          ) {
            response.content[0].article.quantity =
              response.content[0].article.articlePlatforms[0].salePackaging;
            //  On va chercher les dispo de chaque article
            const articleWithAvailabilities = await checkArticleAvailability(
              undefined,
              response.content[0].article
            );
            //  On forme les données complètes sous forme IArticleSearchResult
            const newArticle = {
              ...response.content[0],
              article: {
                ...response.content[0].article,
                moreAvailabilitiesShow:
                  articleWithAvailabilities.moreAvailabilitiesShow,
                listAvailabilities:
                  articleWithAvailabilities.listAvailabilities,
                availability: articleWithAvailabilities.availability,
                moreAvailabilities:
                  articleWithAvailabilities.moreAvailabilities,
              },
            };
            // console.log("newArticle : ", newArticle);
            // if (isArticleEquivalence(newArticle)) {
            //   console.log("newArticle est bien de type IArticleEquivalence");
            // } else {
            //   console.log("newArticle n'est pas de type IArticleEquivalence");
            // }
            // if (isArticleSearchResult(newArticle)) {
            //   console.log("newArticle est bien de type IArticleSearchResult");
            // } else {
            //   console.log("newArticle n'est pas de type IArticleSearchResult");
            // }
            setArticleState(newArticle);
            // equivalenceList.push(newArticle);
          }
        }
      } finally {
        setLoadingState(false);
      }

      // if (
      //   currentItem.article.articlePlatforms &&
      //   currentItem.article.articlePlatforms.length > 0
      // ) {
      //   currentItem.article.quantity =
      //     currentItem.article.articlePlatforms[0].salePackaging;

      //   try {
      //     setLoadingState(true);
      //     const articleWithAvailabilities = await checkArticleAvailability(
      //       undefined,
      //       currentItem.article
      //     );

      //     const newArticle = {
      //       ...currentItem,
      //       article: {
      //         ...currentItem.article,
      //         moreAvailabilitiesShow:
      //           articleWithAvailabilities.moreAvailabilitiesShow,
      //         listAvailabilities: articleWithAvailabilities.listAvailabilities,
      //         availability: articleWithAvailabilities.availability,
      //         moreAvailabilities: articleWithAvailabilities.moreAvailabilities,
      //       },
      //     };
      //     setArticleState(newArticle);
      //   } finally {
      //     setLoadingState(false);
      //   }
      // }
    },
    []
  );

  const onAddOrRemoveBookmarkArticle = useCallback(async () => {
    const customerId = Number(sessionStorage.getItem("customerId"));

    console.log(getTwoToneColor());

    if (articleState) {
      if (!articleState.bookmarked) {
        // Requête d'ajout de l'article entant que bookmark
        let data = {
          article: {
            id: item.article.id,
          },
        };
        try {
          await addArticleBookmark(customerId, data);

          notification.info({
            message: "Favoris",
            description: "L'article a bien été ajouté à vos favoris.",
            duration: 2,
          });
        } catch (error) {
          notification.error({
            message: "Favoris",
            description: "Impossible d'ajout cet article à vos favoris.",
          });
          return;
        }
        onUpdateBookmarkToItemList(articleState);
      } else {
        // Requête de suppression de l'article entant que bookmark
        try {
          await deleteArticleBookmarkByArticleId(
            articleState.article.id,
            customerId
          );

          notification.info({
            message: "Favoris",
            description: "L'article a bien été retiré de vos favoris.",
            duration: 2,
          });
        } catch (error) {
          notification.error({
            message: "Favoris",
            description: "Impossible de retirer cet article de vos favoris.",
          });
          return;
        }
        onUpdateBookmarkToItemList(articleState);
      }
    }
  }, [articleState, item.article.id, onUpdateBookmarkToItemList]);

  useEffect(() => {
    searchAvailabilities(item);
  }, [item, searchAvailabilities]);

  const displayTooltip = useCallback((): string => {
    const tooltipMessage = articleState?.bookmarked
      ? "Cet article est dans vos favoris"
      : "Ajouter cet article au favoris";
    return tooltipMessage;
  }, [articleState?.bookmarked]);

  const displayTitleCard = useCallback(
    (userRole: string, showManufacturer: boolean): React.ReactElement => {
      return (
        <div>
          {articleState && (
            <Row>
              {userRole !== "ROLE_GUEST" && (
                <Col span={1}>
                  <Tooltip title={displayTooltip} placement="right">
                    <Button
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      onClick={onAddOrRemoveBookmarkArticle}
                      type="link"
                    >
                      <StarOutlined
                        style={{
                          fontSize: 20,
                          marginLeft: 10,
                          marginRight: 20,
                          color: articleState.bookmarked
                            ? "#1890ff"
                            : "#BFBFBF",
                        }}
                      />
                    </Button>
                  </Tooltip>
                  {/* )} */}
                </Col>
              )}
              <Col span={20}>
                <Button
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                  }}
                  type="link"
                  onClick={() => onSelectArticle(articleState)}
                >
                  {articleState.article.reference} : {articleState.article.name}
                </Button>
              </Col>
              <Col span={1}>
                {articleState.quantityInWishlists > 0 && (
                  <Tooltip
                    title={`${articleState.quantityInWishlists} dans le panier`}
                    placement="left"
                  >
                    <Spin size="small" spinning={shoppingCartThumbnailState}>
                      <Tag color="orange">
                        {articleState.quantityInWishlists}
                        <ShoppingCartOutlined />
                      </Tag>
                    </Spin>
                  </Tooltip>
                )}
              </Col>
              <Col span={2} style={{ textAlign: "right" }}>
                {showManufacturer && (
                  <Image
                    src={
                      "/api/market/manufacturers/" +
                      articleState.article.manufacturer.id +
                      "/logo?customer=" +
                      customerId
                    }
                    key={articleState.article.reference}
                    alt={articleState.article.reference}
                    style={{ maxWidth: 100, maxHeight: 30 }}
                  />
                )}
              </Col>
            </Row>
          )}
        </div>
      );
    },
    [
      articleState,
      customerId,
      displayTooltip,
      onAddOrRemoveBookmarkArticle,
      onSelectArticle,
      shoppingCartThumbnailState,
    ]
  );

  return (
    <MarketParameterContext.Consumer>
      {(parameters) => (
        <BasketContext.Consumer>
          {(basket) => (
            <Card
              loading={dataLoadingState}
              title={displayTitleCard(
                basket.userRole,
                parameters.SHOW_MANUFACTURER
              )}
              style={
                equivalenceStyle
                  ? { boxShadow: "-5px 5px 15px #CCCCCC" }
                  : undefined
              }
              headStyle={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: "#FAFAFA",
              }}
            >
              {articleState && articleState.known && (
                <>
                  <Row gutter={[8, 16]}>
                    <Col xl={2} lg={4} md={24} sm={24}>
                      <ArticleImage item={item.article} />
                    </Col>
                    <Col xl={8} lg={20} md={24} sm={24}>
                      {articleState && (
                        <ArticleAvailability2
                          articleState={articleState.article}
                          loadingState={loadingState}
                          showAvailability={false}
                          showManufacturer={parameters.SHOW_MANUFACTURER}
                          currentSupplierAvailabilityStatus={
                            parameters.SUPPLIER_AVAILABILITY_STATUS
                          }
                          currentSupplierAvailabilityLabel={
                            parameters.SUPPLIER_AVAILABILITY_LABEL
                          }
                          currentSupplierAvailabilityWarningEnabled={
                            parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
                          }
                          openModalAccount={openModalAccount}
                        />
                      )}
                      {/* </Skeleton> */}
                    </Col>
                    <Col xl={11} lg={16} md={24} sm={24}>
                      {articleState && (
                        <ArticleWishlistForm
                          articleState={articleState.article}
                          currentSupplierAvailabilityStatus={
                            parameters.SUPPLIER_AVAILABILITY_STATUS
                          }
                          onSelectArticlePlatform={(value: number) =>
                            onChangeArticlePlatform(value, item.article)
                          }
                          onChangeQuantity={onChangeArticleQuantity}
                          loading={loadingState}
                          searchAvailabilities={searchAvailabilities}
                          item={item}
                          setShoppingCartThumbnailState={
                            setShoppingCartThumbnailState
                          }
                        />
                      )}
                      <br />
                      {/* TODO : */}
                      {articleState && (
                        <Col xl={24} xxl={12}>
                          <MoreAvailabilities
                            item={articleState.article}
                            loading={loadingState}
                          />
                        </Col>
                      )}
                    </Col>
                    <Col xl={3} lg={8} md={24} sm={24}>
                      {basket.userRole !== "ROLE_GUEST" && articleState && (
                        <ArticlePrice
                          item={articleState?.article}
                          loading={loadingState}
                        />
                      )}
                    </Col>
                  </Row>
                  {showArticleEquivalence && (
                    <Row style={{ marginTop: 15 }}>
                      <Col span={24}>
                        <ArticleEquivalence2
                          item={item.article}
                          onSelectEquivalence={onSelectEquivalence}
                          isModal={isModal}
                          infosTecdoc={infosTecdoc}
                          infosCatalogF1={infosCatalogF1}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Card>
          )}
        </BasketContext.Consumer>
      )}
    </MarketParameterContext.Consumer>
  );
};

export default ArticleCardNew;
