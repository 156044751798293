import React from "react";
import "../../App.css";
import { Row, Col, List, Tabs } from "antd";

import IWishlist from "../home/IWishlist";
import {
  SalesOrderPreviewAddress,
  SalesOrderPreviewHeader,
  SalesOrderPreviewPlatformHeader,
  SalesOrderPreviewPlatformArticle,
} from "./SalesOrderPreviewUtils";
import IWishlistArticle from "../home/IWishlistArticle";
import FlybyList from "../../Utils/FlybyList";
import { SaleOrderResume } from "./ShoppingCartUtils";
import IStep from "./IStep";
import { DefaultParameter } from "../../Utils/MarketParameterContext";

const { TabPane } = Tabs;
class SalesOrderPreview extends React.Component<
  {
    values: any;
    showAddress: boolean;
    stepConf: IStep | undefined;
    parameters: DefaultParameter;
  },
  {}
> {
  render() {
    return (
      <div>
        {this.props.showAddress && (
          <SalesOrderPreviewAddress salesOrder={this.props.values.salesOrder} />
        )}

        <div style={{ fontSize: "0.95em", marginTop: 10 }}>
          <Tabs tabBarStyle={{ margin: 2 }}>
            {this.props.values.basketContent.map((wishlist: IWishlist) => (
              <TabPane tab={wishlist.name} key={wishlist.id.toString()}>
                <div
                  style={{
                    borderLeft: "solid",
                    borderLeftColor: "#e8e8e8",
                    borderLeftWidth: "1px",
                    borderRight: "solid",
                    borderRightColor: "#e8e8e8",
                    borderRightWidth: "1px",
                    borderBottom: "solid",
                    borderBottomColor: "#e8e8e8",
                    borderBottomWidth: "1px",
                  }}
                >
                  <SalesOrderPreviewHeader parameters={this.props.parameters} />

                  <div key={wishlist.id}>
                    <SalesOrderPreviewPlatformHeader wishlist={wishlist} />

                    {wishlist.wishlistArticles.length === 0 && (
                      <Row
                        style={{
                          padding: 5,
                          borderBottom: "solid",
                          borderBottomColor: "#e8e8e8",
                          borderBottomWidth: "1px",
                        }}
                      >
                        <Col span={24} style={{ paddingTop: 5 }}>
                          Aucun article dans le panier.
                        </Col>
                      </Row>
                    )}

                    <FlybyList
                      dataSource={wishlist.wishlistArticles.filter(
                        (item: IWishlistArticle) => item.selected === true
                      )}
                      renderItem={(item: IWishlistArticle) => (
                        <List.Item style={{ padding: 0, margin: 0, border: 0 }}>
                          <SalesOrderPreviewPlatformArticle
                            item={item}
                            parameters={this.props.parameters}
                          />
                        </List.Item>
                      )}
                    ></FlybyList>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>

          {this.props.stepConf !== undefined && (
            <div style={{ marginTop: 10 }}>
              <SaleOrderResume
                keyname={"footerResume"}
                wishlists={this.props.values.basketContent}
                wishlistTotal={this.props.values.wishlistTotal}
                stepConf={this.props.stepConf}
                onChangeReference={undefined}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SalesOrderPreview;
