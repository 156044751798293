import IArticleEquivalence from "./IArticleEquivalence";

export interface IArticleTab {
  defaultTab: string;
  tab1: {
    id: string;
    show: boolean;
    name: string;
  };
  tab2: {
    id: string;
    show: boolean;
    name: string;
  };
  tab3: {
    id: string;
    show: boolean;
    name: string;
  };
  tab4: {
    id: string;
    show: boolean;
    name: string;
  };
  tab5: {
    id: string;
    show: boolean;
    name: string;
  };
  tab6: {
    id: string;
    show: boolean;
    name: string;
  };
  tab7: {
    id: string;
    show: boolean;
    name: string;
  };
}

export const defaultArticleTab = {
  defaultTab: "1",
  tab1: {
    id: "1",
    show: true,
    name: "Equivalence(s)",
  },
  tab2: {
    id: "2",
    show: true,
    name: "Compatibilité",
  },
  tab3: {
    id: "3",
    show: false,
    name: "Caractéristiques",
  },
  tab4: {
    id: "4",
    show: false,
    name: "Caractéristiques",
  },
  tab5: {
    id: "5",
    show: false,
    name: "Types de machines",
  },
  tab6: {
    id: "6",
    show: false,
    name: "Véhicules compatibles",
  },
  tab7: {
    id: "7",
    show: false,
    name: "Documentation",
  },
};

export function isArticleEquivalence(obj: any): obj is IArticleEquivalence {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.id === "number" &&
    typeof obj.equivalentArticle === "object"
    // vérifier d'autres propriétés si nécessaire
  );
}
