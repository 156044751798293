import axios, { AxiosError } from "axios";
import ApiError from "../models/ApiError";
import { isValidationError } from "./ValidationError";
import { Pagination } from "./Pagination";
import PageResponse from "../models/PageResponse";
var qs = require("qs");

export interface ApiConfig {
  timeout: number;
  baseURL: string;
  headers: {};
  token: () => string | undefined;
  userSession: () => string | undefined;
  errorHandler: (error: ApiError) => void;
}

export const apiConfig: ApiConfig = {
  timeout: 30000,
  baseURL: "/api/",
  headers: {
    common: {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  },
  token: () => sessionStorage.getItem("accessToken") || undefined,
  userSession: () => sessionStorage.getItem("userSession") || undefined,
  errorHandler: () => {},
};

const generateApiError = (error: AxiosError): ApiError => {
  const { response } = error;

  return {
    timestamp: response?.data.timestamp ?? new Date(),
    status: response?.status,
    error: response?.statusText ?? error.name,
    message: response?.data?.message ?? error.message,
  };
};

const axiosInstance = axios.create(apiConfig);

// Ajouter un intercepteur pour inclure le token et la session dans les en-têtes
axiosInstance.interceptors.request.use(
  (config) => {
    const token = apiConfig.token();
    const userSession = apiConfig.userSession();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (userSession) {
      config.headers.session = userSession;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export interface GetAllOptions {
  params?: {};
  pagination: Pagination;
}
export interface DeleteWithParamsOptions {
  params?: {};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatUrlHeader = (): any => {
  const token = apiConfig.token();
  const userSession = apiConfig.userSession();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json;charset=UTF-8",
    };
  }
  if (userSession) {
    return {
      session: userSession,
      "Content-Type": "application/json;charset=UTF-8",
    };
  }
};

const handleError = (error: AxiosError): void =>
  apiConfig.errorHandler(generateApiError(error));

export const getNewToken = async (
  username: string,
  password: string
): Promise<string | undefined> => {
  const authorization = Buffer.from("external:").toString("base64");

  const body = qs.stringify({
    grant_type: "password",
    username,
    password,
  });
  try {
    const response = await axiosInstance.post<{ access_token: string }>(
      "/oauth/token",
      body,
      {
        headers: {
          Authorization: `Basic ${authorization}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        validateStatus: (status) => status === 200 || status === 400,
      }
    );

    if (response.status === 400) {
      return undefined;
    }
    const { access_token: token } = response.data;
    apiConfig.token = (): string | undefined => token;
    return token;
  } catch (error) {
    if (isValidationError(error)) {
      handleError(error);
    }
    throw error;
  }
};

export const getAll = async <T>(
  uri: string,
  { params, pagination: { page, size } }: GetAllOptions
): Promise<PageResponse<T>> => {
  const headerContent = formatUrlHeader();
  try {
    const response = await axiosInstance.get(uri, {
      headers: headerContent,
      params: {
        ...params,
        page,
        size,
      },
    });
    return response.data;
  } catch (error) {
    if (isValidationError(error)) {
      handleError(error);
    }
    throw error;
  }
};

export const get = async <T>(uri: string): Promise<T> => {
  const headerContent = formatUrlHeader();

  try {
    const response = await axiosInstance.get(uri, {
      headers: headerContent,
    });
    return response.data;
  } catch (error) {
    if (isValidationError(error)) {
      handleError(error);
    }
    throw error;
  }
};

export const post = async <T>(uri: string, data: T): Promise<T> => {
  const headerContent = formatUrlHeader();

  try {
    const response = await axiosInstance.post(uri, JSON.stringify(data), {
      headers: headerContent,
    });
    return response.data;
  } catch (error) {
    if (isValidationError(error)) {
      handleError(error);
    }
    throw error;
  }
};

export const del = async <T>(uri: string): Promise<T> => {
  const headerContent = formatUrlHeader();

  try {
    const response = await axiosInstance.delete(uri, {
      headers: headerContent,
    });
    return response.data;
  } catch (error) {
    if (isValidationError(error)) {
      handleError(error);
    }
    throw error;
  }
};
