import React from "react";
import {
  Row,
  Col,
  Card,
  Skeleton,
  Tooltip,
  Tag,
  Button,
  Typography,
  Modal,
  Image,
} from "antd";
import { ShoppingCartOutlined, StarOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrownOpen } from "@fortawesome/free-solid-svg-icons";

import IArticleTecdoc from "../../model/IArticle";
import ArticleTab from "./ArticleTab";
import ArticleAvailability from "../../../home/ArticleAvailability";
import ArticleService from "../../../home/ArticleService";
import IArticlePlatforms from "../../../home/IArticlePlatforms";
import ArticleWishlistForm from "../../../home/ArticleWishlistForm";
import ArticleUtils from "../../../home/ArticleUtils";
import { withBasket } from "../../../../Utils/BasketProvider";
import ArticleCard from "../../../home/ArticleCardOld";
import IArticleSearchResult from "../../../home/IArticleSearchResult";
import ArticlePrice from "../../../home/component/ArticlePrice";
import { MarketParameterContext } from "../../../../Utils/MarketParameterContext";
import MoreAvailabilities from "../../../home/component/MoreAvailabilities";

const { Text } = Typography;

class ArticleFlybyDisplay extends React.Component<
  {
    setQuery: any;
    setTypeSearch: any;
    tecdocArticle: IArticleTecdoc;
    onChangeSelect: any;
    onChangeQuantity: any;
  },
  {
    dataLoading: boolean;
    showModal: boolean;
    equivalentArticle: IArticleSearchResult | undefined;
    dataLoadingModal: boolean;
  }
> {
  private articleUtils: ArticleUtils = new ArticleUtils();
  private articleService: ArticleService = new ArticleService();

  private title = (
    item: IArticleTecdoc,
    articleSearchResult: IArticleSearchResult | undefined
  ) => {
    return (
      <div>
        <Row>
          <Col span={22}>
            {articleSearchResult !== undefined &&
              articleSearchResult.bookmarked && (
                <Tooltip
                  title="Cet article est dans vos favoris"
                  placement="right"
                >
                  <StarOutlined
                    style={{ color: "#1890ff", margin: 0, padding: "0px 15px" }}
                  />
                </Tooltip>
              )}
            {articleSearchResult !== undefined &&
              !articleSearchResult.bookmarked && (
                <Tooltip
                  title="Ajouter cet article au favoris"
                  placement="right"
                >
                  <Button
                    onClick={() =>
                      this.articleUtils.onAddBookmark(this, articleSearchResult)
                    }
                    type="link"
                  >
                    <StarOutlined style={{ color: "#BFBFBF" }} />
                  </Button>
                </Tooltip>
              )}
            {item.artNr} :: {item.genericArticle.bez}
          </Col>
          <Col span={2}>
            {articleSearchResult !== undefined &&
              articleSearchResult.quantityInWishlists > 0 && (
                <Tooltip
                  title={`${articleSearchResult.quantityInWishlists} dans le panier`}
                  placement="left"
                >
                  <Tag color="orange">
                    {articleSearchResult.quantityInWishlists}
                    <ShoppingCartOutlined />
                  </Tag>
                </Tooltip>
              )}
          </Col>
        </Row>
      </div>
    );
  };

  constructor(props: {
    setQuery: any;
    setTypeSearch: any;
    tecdocArticle: IArticleTecdoc;
    onChangeSelect: any;
    onChangeQuantity: any;
  }) {
    super(props);

    this.state = {
      dataLoading: false,
      showModal: false,
      equivalentArticle: undefined,
      dataLoadingModal: false,
    };
  }

  render() {
    return (
      <MarketParameterContext.Consumer>
        {(parameters) => (
          <Card
            title={this.title(
              this.props.tecdocArticle,
              this.props.tecdocArticle.articleSearchResult
            )}
            headStyle={{ backgroundColor: "#FAFAFA" }}
          >
            <Row>
              <Col span={4}>
                <Image
                  src={
                    "/tecdoc-api/articles/" +
                    this.props.tecdocArticle.artNr +
                    "/image?country=fr&dlNr=" +
                    this.props.tecdocArticle.supplier.dlnr
                  }
                  alt={"img" + this.props.tecdocArticle.artNr}
                  key={this.props.tecdocArticle.artNr}
                  style={{ width: "100%", maxWidth: 200, maxHeight: 150 }}
                />
              </Col>
              <Col span={10}>
                <Skeleton loading={this.state.dataLoading} active>
                  {this.props.tecdocArticle.articleSearchResult !==
                    undefined && (
                    <ArticleAvailability
                      currentItem={
                        this.props.tecdocArticle.articleSearchResult.article
                      }
                      loading={
                        this.props.tecdocArticle.articleSearchResult.article
                          .loading
                      }
                      showAvailability={false}
                      showManufacturer={parameters.SHOW_MANUFACTURER}
                      currentSupplierAvailabilityLabel={
                        parameters.SUPPLIER_AVAILABILITY_LABEL
                      }
                      currentSupplierAvailabilityStatus={
                        parameters.SUPPLIER_AVAILABILITY_STATUS
                      }
                      currentSupplierAvailabilityWarningEnabled={
                        parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
                      }
                    />
                  )}
                  {this.props.tecdocArticle.articleSearchResult ===
                    undefined && (
                    <Image
                      src={
                        "/tecdoc-api/customSupplierMasterdata/" +
                        this.props.tecdocArticle.supplier.dlnr +
                        "/logo"
                      }
                      key={"img" + this.props.tecdocArticle.supplier.bez}
                      alt={this.props.tecdocArticle.supplier.bez}
                      style={{
                        marginTop: 5,
                        width: 80,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    />
                  )}
                </Skeleton>
              </Col>
              <Col span={10}>
                <Skeleton loading={this.state.dataLoading} active>
                  {this.props.tecdocArticle.articleSearchResult !==
                    undefined && (
                    <div>
                      <ArticleWishlistForm
                        item={
                          this.props.tecdocArticle.articleSearchResult.article
                        }
                        onSelectPlatform={(value: any) =>
                          this.props.onChangeSelect(
                            value,
                            this.props.tecdocArticle.articleSearchResult
                              ?.article
                          )
                        }
                        onChangeQuantity={this.props.onChangeQuantity}
                      />
                      <br />
                      <ArticlePrice
                        item={
                          this.props.tecdocArticle.articleSearchResult.article
                        }
                        loading={
                          this.props.tecdocArticle.articleSearchResult.article
                            .loading
                        }
                      />
                      <br />
                      <MoreAvailabilities
                        item={
                          this.props.tecdocArticle.articleSearchResult.article
                        }
                        loading={
                          this.props.tecdocArticle.articleSearchResult.article
                            .loading
                        }
                      />
                    </div>
                  )}
                  {this.props.tecdocArticle.articleSearchResult ===
                    undefined && (
                    <Row style={{ color: "#faad14" }}>
                      <Col flex={"50px"}>
                        <FontAwesomeIcon
                          icon={faFrownOpen}
                          style={{ fontSize: 40 }}
                        />
                      </Col>
                      <Col style={{ paddingTop: 8 }}>
                        <Text type="warning">
                          Article non disponible à la vente.
                        </Text>
                      </Col>
                    </Row>
                  )}
                </Skeleton>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ArticleTab
                  tecdocArticle={this.props.tecdocArticle}
                  flybyArticle={
                    this.props.tecdocArticle.articleSearchResult?.article
                  }
                  onSelect={this.onSelectEquivalence}
                />
              </Col>
            </Row>

            {this.state.equivalentArticle !== undefined && (
              <Modal
                title="Article équivalent"
                open={this.state.showModal}
                onCancel={() =>
                  this.setState({ showModal: !this.state.showModal })
                }
                width={950}
                footer={[
                  <Button
                    key="back"
                    onClick={() =>
                      this.setState({ showModal: !this.state.showModal })
                    }
                  >
                    Fermer
                  </Button>,
                ]}
              >
                <Skeleton loading={this.state.dataLoadingModal} active>
                  <ArticleCard
                    item={this.state.equivalentArticle}
                    onChangeSelect={this.props.onChangeSelect}
                    onChangeQuantity={this.props.onChangeQuantity}
                    onSelectEquivalence={this.onSelectEquivalence}
                    isModal={true}
                    infosTecdoc={true}
                    infosCatalogF1={true}
                  />
                </Skeleton>
              </Modal>
            )}
          </Card>
        )}
      </MarketParameterContext.Consumer>
    );
  }

  private onSelectEquivalence = (articleId: number) => {
    this.setState({
      dataLoadingModal: true,
    });

    this.articleService.searchArticleById(articleId).then((result: any) => {
      if (result !== undefined && result.content.length > 0) {
        let articleSearch = result.content[0];
        let articlePlatform: IArticlePlatforms =
          articleSearch.article.articlePlatforms[0];

        if (articlePlatform !== undefined) {
          articleSearch.article.loading = true;
          articleSearch.article.quantity = articlePlatform.salePackaging;
          articleSearch.article.equivalence = {
            loading: true,
            list: [],
          };

          if (articleSearch.known) {
            this.articleUtils.checkArticleAvailability(
              undefined,
              articleSearch.article,
              false,
              this
            );
            this.articleUtils.findEquivalences(
              articleSearch.article,
              true,
              this
            );
          }
        }

        this.setState({
          showModal: true,
          equivalentArticle: articleSearch,
          dataLoadingModal: false,
        });
      }
    });
  };
}

export default withBasket(ArticleFlybyDisplay);
