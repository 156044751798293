import React from "react";
import { notification } from "antd";

import IArticlePlatforms from "./IArticlePlatforms";
import IArticle from "./IArticle";
import IAvailability from "./IAvailability";
import IArticleEquivalence from "./IArticleEquivalence";
import IArticleSearchResult from "./IArticleSearchResult";
import {
  articleAvailability,
  findEquivalences,
} from "./ArticleServiceFunctional";
import { findInitParametersByPlatform } from "../../Utils/ParameterServiceFunctional";
import { addArticleBookmarkOld } from "../bookmark/BookmarkServiceFunctional";

// const attachedPlatformId: number = Number(
//   sessionStorage.getItem("attachedPlatformId")
// );

export const checkArticleAvailability = async (
  platformId: number | undefined,
  item: IArticle
): Promise<IArticle> => {
  let newItem: IArticle = item;
  const response: IAvailability[] = await articleAvailability(
    item.id,
    item.quantity,
    undefined
  );
  newItem = {
    ...newItem,
    moreAvailabilitiesShow: false,
    listAvailabilities: response,
  };

  let moreAvailabilities: IAvailability[] = [];

  if (response !== undefined) {
    if (platformId === undefined) {
      newItem = {
        ...newItem,
        availability: response[0],
      };

      if (newItem?.availability?.state !== "AVAILABLE") {
        newItem = {
          ...newItem,
          moreAvailabilitiesShow: true,
        };
      }

      response.forEach((availability: IAvailability) => {
        if (
          newItem.availability !== undefined &&
          availability.platform.id !== newItem.availability.platform.id &&
          availability.state !== "UNAVAILABLE"
        ) {
          moreAvailabilities.push(availability);
        }
      });
    }

    if (platformId !== undefined) {
      response.forEach((currentAvailability: IAvailability) => {
        if (currentAvailability.platform.id === platformId) {
          newItem = {
            ...newItem,
            availability: currentAvailability,
          };

          if (currentAvailability.state !== "AVAILABLE") {
            newItem = {
              ...newItem,
              moreAvailabilitiesShow: true,
            };
            // newItem.moreAvailabilitiesShow = true;
          }
        } else if (
          currentAvailability.platform.id !== platformId &&
          currentAvailability.state !== "UNAVAILABLE"
        ) {
          moreAvailabilities.push(currentAvailability);
        }
      });
    }

    newItem = {
      ...newItem,
      moreAvailabilities: moreAvailabilities,
      loading: false,
    };
  } else {
    newItem = {
      ...newItem,
      loading: false,
    };
  }
  return newItem;
};

export const checkQuantityVsSalePackaging = (
  quantity: number,
  salePackaging: number
): boolean => {
  let success = true;

  if (quantity > 0 && quantity % salePackaging) {
    success = false;
    notification.error({
      message: "Quantité",
      description:
        "La quantité renseignée n'est pas correcte. Elle doit être un multiple du conditionnement de vente.",
    });
  }

  return success;
};

export const onChangeQuantity = async (
  // parent: React.Component,
  quantity: number,
  platformId: number,
  item: IArticle
): Promise<IArticle> => {
  const attachedPlatformId: number = Number(
    sessionStorage.getItem("attachedPlatformId")
  );
  let articlePlatform: IArticlePlatforms | undefined =
    item.articlePlatforms.find(
      (element: IArticlePlatforms) => element.id === attachedPlatformId
    );

  if (articlePlatform !== undefined) {
    if (checkQuantityVsSalePackaging(quantity, articlePlatform.salePackaging)) {
      const newItem = await checkArticleAvailability(
        // parent,
        platformId,
        item
      );
      return newItem;
    }
  }
  return item;
};

export const onChangePlatform = async (
  // parent: React.Component,
  platformId: number,
  item: IArticle
): Promise<IArticle> => {
  const newItem = await checkArticleAvailability(
    // parent,
    platformId,
    item
  );
  return newItem;
};

export const getSpecificAvailability = (
  parent: React.Component,
  itemAvailability: IAvailability
): IAvailability => {
  const accessToken = sessionStorage.getItem("accessToken");
  if (accessToken) {
    findInitParametersByPlatform(
      accessToken,
      itemAvailability.platform.id,
      "SUPPLIER_AVAILABILITY_STATUS"
    ).then((value: string) => {
      if (value) {
        itemAvailability.state = value;
        parent.forceUpdate();
      }
    });
    findInitParametersByPlatform(
      accessToken,
      itemAvailability.platform.id,
      "SUPPLIER_AVAILABILITY_WARNING_ENABLED"
    ).then((value: string) => {
      if (value) {
        itemAvailability = {
          ...itemAvailability,
          quantityBackorder: value === "false" ? -1 : 1,
        };
      }
    });
    return itemAvailability;
  }
  return itemAvailability;
};

export const findArticleEquivalences = async (
  item: IArticle
): Promise<IArticle> => {
  let newItem: IArticle = item;
  const response = await findEquivalences(item.id, 50, 0);
  if (
    response !== undefined &&
    response !== undefined &&
    newItem.equivalence !== undefined
  ) {
    newItem.equivalence.loading = false;
    newItem.equivalence.list = response;

    newItem.equivalence.list.forEach((equivalent: IArticleEquivalence) => {
      equivalent.equivalentArticle.quantity = 1;
    });
  }
  return newItem;
};

export const onAddBookmark = async (
  // parent: React.Component,
  item: IArticleSearchResult
): Promise<void> => {
  try {
    await addArticleBookmarkOld(item.article.id);
  } catch (error) {
    console.log(error);
  }
};

export function isArticleSearchResult(obj: any): obj is IArticleSearchResult {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.bookmarked === "boolean" &&
    typeof obj.known === "boolean" &&
    typeof obj.quantityInWishlists === "number"
    // vérifier d'autres propriétés si nécessaire
  );
}

// class ArticleUtils {
//   accessToken(accessToken: any, id: any, arg2: string) {
//     throw new Error("Method not implemented.");
//   }
//   setState(arg0: { currentAvailabilityState: string }) {
//     throw new Error("Method not implemented.");
//   }
//   // private attachedPlatformId: number = Number(
//   //   sessionStorage.getItem("attachedPlatformId")
//   // );

//   props: any;
// }

// export default ArticleUtils;
