import React from "react";
import "../../App.css";
import { DeleteOutlined } from "@ant-design/icons";
import {
  Table,
  Button,
  Popconfirm,
  Typography,
  Row,
  Col,
  notification,
} from "antd";
import { ColumnProps } from "antd/lib/table";

import IData from "../../Utils/IData";
import FlybyUtils from "../../Utils/FlybyUtils";
import BookmarkService from "./BookmarkService";
import { withBasket } from "../../Utils/BasketProvider";
import ArticleWishlistForm from "./ArticleWishlistForm";
import IArticle from "../home/IArticle";
import ArticleService from "../home/ArticleService";
import ArticleAvailability from "../home/ArticleAvailability";
import MoreAvailabilities from "../home/component/MoreAvailabilities";
import IAvailability from "../home/IAvailability";
import IArticlePlatforms from "../home/IArticlePlatforms";
import ArticleUtils from "../home/ArticleUtils";
import ArticlePrice from "../home/component/ArticlePrice";
import { MarketParameterContext } from "../../Utils/MarketParameterContext";
import { IBookmark } from "./IBookmark";
import { deleteArticleBookmark } from "./BookmarkServiceFunctional";

const { Text } = Typography;

class Bookmark extends React.Component<
  { addArticle: any },
  {
    data: IData;
    dataLoading: boolean;
  }
> {
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private bookmarkService: BookmarkService = new BookmarkService();
  private articleService: ArticleService = new ArticleService();
  private articleUtils: ArticleUtils = new ArticleUtils();

  private attachedPlatformId: number = Number(
    sessionStorage.getItem("attachedPlatformId")
  );

  private columns: ColumnProps<IBookmark>[] = [
    {
      title: "EAN(s)",
      dataIndex: "ean",
      width: 150,
      render: (text: string, record: any) => (
        <div>{this.flybyUtils.getEans2(record.article.eans)}</div>
      ),
    },
    {
      title: "Référence",
      dataIndex: "article.reference",
      render: (text: string, record: any) => (
        <div>{record.article.reference}</div>
      ),
    },
    {
      title: "Libellé",
      dataIndex: "article.name",
      render: (text: string, record: any) => <div>{record.article.name}</div>,
    },
    {
      title: "Code fabricant",
      dataIndex: "article.manufacturer.code",
      render: (text: string, record: any) => (
        <div>{record.article.manufacturer.code}</div>
      ),
    },
    {
      title: "Fabricant",
      dataIndex: "article.manufacturer.name",
      render: (text: string, record: any) => (
        <div>{record.article.manufacturer.name}</div>
      ),
    },
    {
      title: "",
      width: 500,
      render: (text: string, record: any) => (
        <div style={{ width: 350, float: "right", height: 54, paddingTop: 10 }}>
          <ArticleWishlistForm
            item={record.article}
            onSelectPlatform={(value: any) =>
              this.onChangeSelect(value, record.article)
            }
            onChangeQuantity={this.onChangeQuantity}
          />
        </div>
      ),
    },
    {
      title: "",
      width: 40,
      render: (text: string, record: any) => (
        <div style={{ float: "right", height: 54, paddingTop: 10 }}>
          <Popconfirm
            title={"Supprimer l'article de mes favoris ?"}
            okText="Oui"
            cancelText="Non"
            onConfirm={() => this.deleteBookmark(record.id, record)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  constructor(props: { addArticle: any }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
    };
  }

  componentDidMount() {
    this.searchBookmark("", 20, 0, "id");
  }

  render() {
    return (
      <div>
        <MarketParameterContext.Consumer>
          {(parameters) => (
            <Table
              rowKey="id"
              loading={this.state.dataLoading}
              columns={this.columns}
              dataSource={this.state.data.content}
              onChange={this.onChange}
              pagination={{
                current: this.state.data.number + 1,
                pageSize: this.state.data.size,
                total: this.state.data.totalElements,
              }}
              onExpand={this.onExpand}
              expandedRowRender={(
                record: IBookmark,
                index: number,
                indent: number,
                expanded: boolean
              ) => (
                <Text type="secondary">
                  {expanded && (
                    <Row>
                      <Col flex="600px">
                        <ArticleAvailability
                          currentItem={record.article}
                          loading={record.article.loading}
                          showAvailability={false}
                          showManufacturer={parameters.SHOW_MANUFACTURER}
                          currentSupplierAvailabilityLabel={
                            parameters.SUPPLIER_AVAILABILITY_LABEL
                          }
                          currentSupplierAvailabilityStatus={
                            parameters.SUPPLIER_AVAILABILITY_STATUS
                          }
                          currentSupplierAvailabilityWarningEnabled={
                            parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
                          }
                        />
                      </Col>
                      <Col flex="130px">
                        <ArticlePrice
                          item={record.article}
                          loading={record.article.loading}
                        />
                      </Col>
                      <Col flex="auto">
                        <MoreAvailabilities
                          item={record.article}
                          loading={record.article.loading}
                        />
                      </Col>
                    </Row>
                  )}
                </Text>
              )}
              style={{
                border: "1px solid #e8e8e8",
                borderWidth: "1px 1px 1px 1px",
              }}
              size="middle"
            />
          )}
        </MarketParameterContext.Consumer>
      </div>
    );
  }

  private onChangeSelect = (platformId: number, item: IArticle) => {
    this.checkArticleAvailability(platformId, item);
  };

  private deleteBookmark = async (bookmarId: number, record: any) => {
    try {
      this.setState({
        dataLoading: true,
      });
      const customerId = Number(sessionStorage.getItem("customerId"));
      await deleteArticleBookmark(bookmarId, customerId);
      notification.info({
        message: "Favoris",
        description: "L'article a bien été retiré de vos favoris.",
        duration: 2,
      });
    } catch (error) {
      notification.error({
        message: "Favoris",
        description:
          "Erreur lors de la suppression de l'article de vos favoris.",
      });
    } finally {
      this.setState({
        dataLoading: false,
      });
    }
    this.searchBookmark("", 20, 0, "id");
  };

  private onChangeQuantity = (
    quantity: number,
    platformId: number,
    item: IArticle
  ) => {
    let articlePlatform: IArticlePlatforms | undefined =
      item.articlePlatforms.find(
        (x: IArticlePlatforms) => x.id === this.attachedPlatformId
      );

    if (articlePlatform !== undefined) {
      if (
        this.articleUtils.checkQuantityVsSalePackaging(
          quantity,
          articlePlatform.salePackaging
        )
      ) {
        this.checkArticleAvailability(platformId, item);
      }
    }
  };

  private onChange = (
    pagination: any,
    filters: Record<never, string[]>,
    sorter: any,
    extra: any
  ) => {
    let sort = this.flybyUtils.getSort(sorter.field, sorter.order);
    pagination = this.flybyUtils.getCurrentPage(pagination);

    this.searchBookmark("", pagination.pageSize, pagination.current, sort);
  };

  private onExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      let articlePlatform: IArticlePlatforms =
        record.article.articlePlatforms[0];

      let defaultPlatform: number | undefined = this.attachedPlatformId;
      if (
        record.article.articlePlatforms.length === 1 &&
        articlePlatform.id !== this.attachedPlatformId
      ) {
        defaultPlatform = articlePlatform.id;
      }

      if (
        articlePlatform !== undefined &&
        record.article.availability === undefined
      ) {
        record.article.quantity = articlePlatform.salePackaging;
        this.checkArticleAvailability(defaultPlatform, record.article);
      }

      if (
        articlePlatform !== undefined &&
        record.article.availability !== undefined
      ) {
        this.checkArticleAvailability(
          record.article.availability.platform.id,
          record.article
        );
      }
    }
  };

  private checkArticleAvailability = (platformId: number, item: IArticle) => {
    let idx = this.state.data.content.findIndex(
      (obj: any) => obj.article.id === item.id
    );
    let listItems: any = [...this.state.data.content];
    let data = this.state.data;

    listItems[idx].article.loading = true;
    data.content = listItems;
    this.setState({ data: data });

    this.articleService
      .articleAvailability(item.id, item.quantity, undefined)
      .then((result: IAvailability[]) => {
        if (result !== undefined) {
          listItems[idx].article.moreAvailabilitiesShow = false;
          let moreAvailabilities: IAvailability[] = [];
          item.listAvailabilities = result;

          result.forEach((availability: IAvailability) => {
            if (availability.platform.id === platformId) {
              item.availability = availability;

              if (availability.state !== "AVAILABLE") {
                item.moreAvailabilitiesShow = true;
              }
            } else if (
              availability.platform.id !== platformId &&
              availability.state !== "UNAVAILABLE"
            ) {
              moreAvailabilities.push(availability);
            }
          });

          listItems[idx].article.moreAvailabilities = moreAvailabilities;
          listItems[idx].article.loading = false;

          data.content = listItems;
          this.setState({ data: data });
        } else {
          item.loading = false;
          this.forceUpdate();
        }
      });
  };

  private searchBookmark = (
    query: string,
    pageSize: number | undefined,
    current: number | undefined,
    sort: string
  ) => {
    this.setState({
      dataLoading: true,
    });

    this.bookmarkService
      .searchBookmarks(query, pageSize, current, sort)
      .then((data: any) => {
        if (data !== undefined) {
          console.log("data : ", data);
          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  };
}

export default withBasket(Bookmark);
