import IArticle from "./IArticle";
import IWishlistArticle from "./IWishlistArticle";
import IAvailability from "./IAvailability";
import IFilterQuery from "../../Utils/IFilterQuery";
import IMasterdataSupplier from "./IMasterdataSupplier";
import IData from "../../Utils/IData";
import IArticleUsage from "./IArticleUsage";
import IArticleAvailabilityRequestInformation from "./IArticleAvailabilityRequestInformation";
import { getEmptyData } from "../../Utils/FlybyUtilsFunctional";
import IArticleEquivalence from "./IArticleEquivalence";

export const getArticlesWithQuery = async (
  query: string,
  filterQuery: IFilterQuery,
  size: number | undefined,
  page: number | undefined
  // ): Promise<IArticleSearchResult | IData> => {
): Promise<IData> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  const manufacturersFilter = sessionStorage.getItem("manufacturersFilter");

  let runSearch = false;
  let params = "customer=" + customerId;

  if (filterQuery.families.length > 0) {
    params += "&families=" + filterQuery.families.toString();
  }

  if (filterQuery.manufacturers.length > 0) {
    params += "&manufacturers=" + filterQuery.manufacturers.toString();
  }

  if (query !== "") {
    params += "&query=" + query;
    params += "&advancedQuery=" + filterQuery.extended;
    runSearch = true;
  }

  if (manufacturersFilter !== null && manufacturersFilter !== "") {
    params += "&manufacturers=" + manufacturersFilter;
  }

  if (runSearch) {
    return fetch(
      "/api/market/articleSearchResults?" +
        params +
        "&size=" +
        size +
        "&page=" +
        page,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  return Promise.resolve(getEmptyData());
};

export const articleAvailability = async (
  articleId: number,
  quantity: number,
  platformId: number | undefined,
  sourceWishlistArticleId?: number
): Promise<IAvailability[]> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  let url =
    "article=" +
    articleId +
    "&customer=" +
    customerId +
    "&quantity=" +
    quantity;
  if (sourceWishlistArticleId !== undefined) {
    url += "&sourceWishlistArticle=" + sourceWishlistArticleId;
  }
  if (platformId !== undefined) {
    url += "&platform=" + platformId;
  }

  return fetch("/api/market/articleAvailabilities?" + url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error(response.status + " - " + response.statusText);
    })
    .then((response: any) => {
      return response.content;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const getArticleById = async (id: number): Promise<IData> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  const manufacturersFilter = sessionStorage.getItem("manufacturersFilter");

  let url = "?article=" + id + "&customer=" + customerId;
  if (manufacturersFilter !== null && manufacturersFilter !== "") {
    url += "&manufacturers=" + manufacturersFilter;
  }

  return fetch("/api/market/articleSearchResults" + url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleById = async (id: number): Promise<IArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch("/api/market/articles/" + id + "?customer=" + customerId, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findMasterDataSupplier = async (
  dlnr: number
): Promise<IMasterdataSupplier> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/masterDataSuppliers/tecdocCode/" + dlnr + "?customer=" + customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const searchArticleByReference = async (
  cleanReference: string,
  manufacturerTeccomCode: string
): Promise<IArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  const manufacturersFilter = sessionStorage.getItem("manufacturersFilter");

  let url =
    "?cleanReference=" +
    cleanReference +
    "&manufacturerTeccomCode=" +
    manufacturerTeccomCode +
    "&customer=" +
    customerId;
  if (manufacturersFilter !== null && manufacturersFilter !== "") {
    url += "&manufacturers=" + manufacturersFilter;
  }

  return fetch("/api/market/articleSearchResults" + url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const searchArticleByEanOrReference = async (
  ean: string,
  cleanReference: string,
  manufacturerTeccomCode: string
): Promise<IArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  const manufacturersFilter = sessionStorage.getItem("manufacturersFilter");

  let url =
    "?ean=" +
    ean +
    "&cleanReference=" +
    cleanReference +
    "&manufacturerTeccomCode=" +
    manufacturerTeccomCode +
    "&customer=" +
    customerId;
  if (manufacturersFilter !== null && manufacturersFilter !== "") {
    url += "&manufacturers=" + manufacturersFilter;
  }

  return fetch("/api/market/articleSearchResults" + url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleByEan = async (ean: string): Promise<IArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch("/api/market/articles?ean=" + ean + "&customer=" + customerId, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleByCleanReference = async (
  cleanReference: string,
  manufacturerTeccomCode: string
): Promise<IArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/articles?cleanReference=" +
      cleanReference +
      "&manufacturerTeccomCode=" +
      manufacturerTeccomCode +
      "&customer=" +
      customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findEquivalences = async (
  articleId: number,
  size?: number | undefined,
  page?: number | undefined
): Promise<IArticleEquivalence[]> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/equivalences?customer=" +
      customerId +
      "&article=" +
      articleId +
      "&size=" +
      size +
      "&page=" +
      page,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error(response.status + " - " + response.statusText);
    })
    .then((response: any) => {
      return response.content;
    })
    .catch((err: any) => {
      console.log(err);
    });
  // .then((response: any) => {
  //   return response.json();
  // })
  // .catch((err: any) => {
  //   console.log(err);
  // });
};

export const findTags = async (
  articleId: number,
  type: string | undefined
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  let url =
    "/api/market/articleTags?customer=" +
    customerId +
    "&article=" +
    articleId +
    "&size=300";
  if (type !== undefined) {
    url += "&type=" + type;
  }

  return fetch(url, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const updateWishlistArticle = async (
  wishlistArticleId: number,
  wishlistArticles: IWishlistArticle
): Promise<IWishlistArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/wishlistArticles/" +
      wishlistArticleId +
      "?customer=" +
      customerId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "PUT",
      body: JSON.stringify(wishlistArticles),
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const wishlistArticle = async (
  wishlistArticleId: number,
  refreshAvailability: boolean
): Promise<IWishlistArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch(
    "/api/market/wishlistArticles/" +
      wishlistArticleId +
      "?customer=" +
      customerId +
      "&refreshAvailability=" +
      refreshAvailability,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const transferWishlistArticle = async (
  wishlistArticleId: number,
  platformId: number,
  transferQuantity: number
): Promise<IWishlistArticle> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  let data = {
    sourceWishlistArticle: {
      id: wishlistArticleId,
    },
    quantity: transferQuantity,
  };

  return fetch(
    "/api/market/transferWishlistArticles?customer=" +
      customerId +
      "&platform=" +
      platformId,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(data),
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findLogo = async (manufacturerId: number): Promise<string> => {
  const accessToken = sessionStorage.getItem("accessToken");
  return fetch("/api/market/manufacturers/" + manufacturerId + "/logo", {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "image/jpeg",
      "Content-Type": "image/jpeg",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.blob().then((images) => {
          let objectURL = URL.createObjectURL(images);
          return objectURL;
        });
      } else {
        return "";
      }
    })
    .catch((err: any) => {
      return "";
    });
};

export const findArticlePicture = async (
  articleId: number
): Promise<string> => {
  const accessToken = sessionStorage.getItem("accessToken");
  return fetch("/api/market/articles/" + articleId + "/image", {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "image/jpeg",
      "Content-Type": "image/jpeg",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.blob().then((images) => {
          let objectURL = URL.createObjectURL(images);
          return objectURL;
        });
      } else {
        return "";
      }
    })
    .catch((err: any) => {
      return "";
    });
};
export const searchCatalogArticle = async (
  vehicleTypeId: number | undefined,
  familyId: number | undefined,
  modelYear: number | undefined,
  size: number | undefined,
  page: number | undefined
): Promise<IData> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  const manufacturersFilter = sessionStorage.getItem("manufacturersFilter");

  let uri =
    "/api/market/articleSearchResults?customer=" +
    customerId +
    "&size=" +
    size +
    "&page=" +
    page;

  if (vehicleTypeId !== undefined) {
    uri += "&vehicleType=" + vehicleTypeId;
  }

  if (familyId !== undefined) {
    uri += "&family=" + familyId;
  }

  if (modelYear !== undefined) {
    uri +=
      "&articleCriterionFilter=(criterion.code==DDATE and value=le=" +
      modelYear +
      "0101)&articleCriterionFilter=(criterion.code==FDATE and value=ge=" +
      modelYear +
      "1231)";
  }

  if (manufacturersFilter !== null && manufacturersFilter !== "") {
    uri += "&manufacturers=" + manufacturersFilter;
  }

  return fetch(uri, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleUsage = async (id: number): Promise<IArticleUsage> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch("/api/market/articles/" + id + "/usage?customer=" + customerId, {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: Response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findArticleDocuments = async (
  articleId: number,
  size: number | undefined,
  page: number | undefined
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");

  return fetch(
    "/api/articles/" +
      articleId +
      "/articleDocuments?size=" +
      size +
      "&page=" +
      page +
      "&sort=name",
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: Response) => {
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const postRequest = async (
  articleId: number,
  articleAvailabilityRequestInformation: IArticleAvailabilityRequestInformation
): Promise<any> => {
  const accessToken = sessionStorage.getItem("accessToken");

  return fetch(
    "/api/market/articleAvailabilities/" + articleId + "/requestInformation",
    {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(articleAvailabilityRequestInformation),
    }
  )
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => {
      console.log(err);
    });
};
