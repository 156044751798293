import { AxiosError } from "axios";
import { ValidationErrorDetail } from "./ValidationErrorDetail";

export interface ValidationError {
  details: ValidationErrorDetail[];
  value: unknown;
}

export function isValidationError(
  error: unknown | AxiosError<ValidationError>
): error is AxiosError<ValidationError> {
  const validationError = error as AxiosError<ValidationError>;
  return (
    validationError.isAxiosError && !!validationError.response?.data.details
  );
}

export function isBlobValidationError(
  error: unknown | AxiosError<ValidationError>
): error is AxiosError<ValidationError> {
  const validationError = error as AxiosError<ValidationError>;
  return validationError.isAxiosError && !!validationError.response;
}

export const getErrorMessage = (error: AxiosError<ValidationError>): string => {
  let errMessage = "";
  error.response?.data.details.forEach((element: ValidationErrorDetail) => {
    element.messages.forEach((messages: string) => {
      errMessage = `${errMessage} ${messages}`;
    });
  });
  return errMessage;
};
