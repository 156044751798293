import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Table,
  Typography,
  List,
  Tag,
  Skeleton,
  Button,
  Modal,
  message,
  Divider,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import { BasketContext } from "../../Utils/BasketProvider";
import IData from "../../Utils/IData";
import IArticleSearchResult from "./IArticleSearchResult";
import AdvancedSearch from "./AdvancedSearch";
import IFilterQuery from "../../Utils/IFilterQuery";
import { WarningOutlined } from "@ant-design/icons";
import ArticleWishlistForm from "./ArticleWishlistForm";
import OpenAccount from "../login/OpenAccount";
import {
  checkArticleAvailability,
  findArticleEquivalences,
  onChangePlatform,
  onChangeQuantity,
} from "./ArticleUtilsFunctional";
import {
  findEquivalences,
  getArticlesWithQuery,
  getArticleById,
  searchCatalogArticle,
} from "./ArticleServiceFunctional";
import ArticleCard2 from "./ArticleCardNew";
import {
  formatDeliveredAt,
  getAvailabilityColor,
  getAvailabilityText,
  getCurrentPage,
  getEans,
  getEmptyData,
  isSupplierAvailability,
  numberFormat,
} from "../../Utils/FlybyUtilsFunctional";
// import { isArticleEquivalence } from "./ArticleEquivalenceUtilsFunctional";
import IArticleEquivalence from "./IArticleEquivalence";
import ArticleCardNew from "./ArticleCardNew";
import IArticle from "./IArticle";

const { Text } = Typography;

interface ArticleDisplayProps {
  dataProps?: IData;
}

const ArticleDisplay = ({
  dataProps,
}: ArticleDisplayProps): React.ReactElement => {
  const {
    // addArticle,
    setQuery,
    // setAdvancedQuery,
    query,
    advancedQuery,
    setTypeSearch,
    typeSearch,
    catalogSearch,
    filterQuery,
    articleView,
    selectedArticle,
    setSelectedArticle,
    onArticleDeleted,
  } = useContext(BasketContext);

  let pageSizeList: number = 10;
  let pageSizeTable: number = 10;

  const tecdocShowInfo = sessionStorage.getItem("tecdocShowInfo");
  const infosTecdocRef = tecdocShowInfo
    ? JSON.parse(tecdocShowInfo)
    : undefined;

  const catalogF1 = sessionStorage.getItem("catalogF1");
  const infosCatalogF1 = catalogF1 ? JSON.parse(catalogF1) : undefined;

  const [dataState, setDataState] = useState<IData>(getEmptyData());
  const [
    selectedArticleSearchResultState,
    setSelectedArticleSearchResultState,
  ] = useState<IArticleSearchResult>();
  const [dataLoadingState, setDataLoadingState] = useState<boolean>(false);
  const [openModalAccountState, setOpenModalAccountState] =
    useState<boolean>(false);
  const [currentArticleEquivalenceList, setCurrentArticleEquivalenceList] =
    useState<IArticleEquivalence[]>([]);
  const [deletedArticleState, setDeletedArticleState] = useState<IArticle>();

  // UseEffect à l'écoute du context pour récupéré l'IArticle
  // qui est supprimé dans le panier afin de recalculer les availabilities
  useEffect(() => {
    const handleArticleDeleted = (deletedArticle: any) => {
      setDeletedArticleState(deletedArticle);
    };

    onArticleDeleted(handleArticleDeleted);

    // Cleanup function
    return () => {
      onArticleDeleted(() => {});
    };
  }, [onArticleDeleted]);

  // Recherches pour chaque article les availabilities
  const searchAvailabilities = useCallback(
    async (data: IData, query?: string) => {
      let newDatas = data;

      const newArticleSearchResult: IArticleSearchResult[] = [];

      if (newDatas !== undefined && newDatas.content !== undefined) {
        // Boucle sur le data.content pour aller chercher ds disponibilités et des équivalences
        for (const newData of newDatas.content) {
          if (
            newData.article.articlePlatforms &&
            newData.article.articlePlatforms.length > 0
          ) {
            newData.article.loading = true;
            newData.article.quantity =
              newData.article.articlePlatforms[0].salePackaging;
            newData.article.equivalence = {
              loading: true,
              list: [],
            };

            if (newData.known) {
              try {
                const articleWithAvailabilities =
                  await checkArticleAvailability(undefined, newData.article);

                const articleWithEquivalence = await findArticleEquivalences(
                  newData.article
                );

                // reconstruction de l'object par rapport à ce que l'on récupère
                // de "checkArticleAvailability" et "findArticleEquivalences"
                const newArticle = {
                  ...newData,
                  article: {
                    ...newData.article,
                    moreAvailabilitiesShow:
                      articleWithAvailabilities.moreAvailabilitiesShow,
                    listAvailabilities:
                      articleWithAvailabilities.listAvailabilities,
                    availability: articleWithAvailabilities.availability,
                    moreAvailabilities:
                      articleWithAvailabilities.moreAvailabilities,
                    loading: articleWithAvailabilities.loading,
                    equivalence: articleWithEquivalence.equivalence,
                  },
                };
                newArticleSearchResult.push(newArticle);
              } catch (error) {
                console.error("Error processing article:", error);
              }
            }
          }
        }
        setDataState({
          ...newDatas,
          content: newArticleSearchResult,
        });

        if (query) {
          setQuery(query);
        }
      } else {
        setDataLoadingState(false);
      }
    },
    [setQuery]
  );

  // Mise à jour de la liste des data avec le nouvel valeur item d'un article
  const refreshArticles = useCallback(async (deletedArticleState: IArticle) => {
    try {
      if (deletedArticleState) {
        const response = await getArticleById(deletedArticleState?.id);
        setDataState((prevState) => {
          const updatedContent = prevState.content.map(
            (element: IArticleSearchResult) =>
              element.article.id === deletedArticleState.id
                ? { ...response.content[0] }
                : element
          );
          return { ...prevState, content: updatedContent };
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Si il y a un deletedArticleState alors on refresh la liste d'article
  // pour que la ArticleCardNew détecte un changement de l'article concerné
  // alors elle déclenche une nouvelle recherche d'availability
  useEffect(() => {
    if (deletedArticleState) {
      refreshArticles(deletedArticleState);
    }
  }, [deletedArticleState, refreshArticles]);

  // Recherche des articles avec le query
  const searchArticles = useCallback(
    async (
      query: string,
      filterQuery: IFilterQuery,
      currentPage: number | undefined,
      pageSize?: number | undefined
    ) => {
      setDataLoadingState(true);
      try {
        const response = await getArticlesWithQuery(
          query,
          filterQuery,
          pageSize,
          currentPage
        );
        if (response !== undefined) {
          setDataState(response);
          // await searchAvailabilities(response, query);
          setTypeSearch(1);
        }
      } finally {
        setDataLoadingState(false);
      }
    },
    [setTypeSearch]
  );

  const searchCatalogArticles = useCallback(
    async (
      vehicleTypeId: number | undefined,
      familyId: number | undefined,
      modelYear: number | undefined,
      pageSize: number | undefined,
      current: number | undefined
    ) => {
      setDataLoadingState(true);

      try {
        const response = await searchCatalogArticle(
          vehicleTypeId,
          familyId,
          modelYear,
          pageSize,
          current
        );
        if (response !== undefined) {
          await searchAvailabilities(response);
          setTypeSearch(3);
          // document?.getElementById("itemsDiv")?.scrollIntoView();
        }
      } finally {
        setDataLoadingState(false);
      }
    },
    [searchAvailabilities, setTypeSearch]
  );

  const onOpenModalAccount = () => {
    setOpenModalAccountState(true);
  };

  const onCloseModalAccount = () => {
    setOpenModalAccountState(false);
  };

  const onPageSize = useCallback(
    (articleView?: string): number => {
      if (articleView === "table") {
        return pageSizeTable;
      }
      if (articleView === "list") {
        return pageSizeList;
      }
      return 10;
    },
    [pageSizeList, pageSizeTable]
  );

  const onChangeList2 = (pageNumber: number, pageSize?: number) => {
    if (typeSearch === 3) {
      searchCatalogArticles(
        catalogSearch?.level3Id,
        catalogSearch?.level5Id,
        catalogSearch?.modelYear,
        pageNumber - 1,
        onPageSize(articleView)
      );
    } else {
      searchArticles(
        query,
        filterQuery,
        pageNumber - 1,
        pageSize ? pageSize : onPageSize(articleView)
      );
    }
  };

  const onFilter = () => {
    searchArticles(query, filterQuery, 0, onPageSize(articleView));
  };

  const searchArticlesFromPreviousList = useCallback(
    (data: IData) => {
      setDataLoadingState(true);
      if (data.size === 0) {
        data.size = onPageSize(articleView);
      }
      // searchAvailabilities(data);
    },
    [articleView, onPageSize]
  );

  // const onChangeQuantity = (
  //   quantity: number,
  //   platformId: number,
  //   item: IArticle
  // ) => {
  //   onChangeQuantity(quantity, platformId, item);
  // };

  // const onChangeSelect = (platformId: number, item: IArticle) => {
  //   articleUtilsMemo.onChangeSelect(platformId, item);
  // };

  const onChange = (
    pagination: any,
    filters: Record<never, string[]>,
    sorter: any,
    extra: any
  ) => {
    pagination = getCurrentPage(pagination);

    if (typeSearch === 3) {
      searchCatalogArticles(
        catalogSearch?.level3Id,
        catalogSearch?.level5Id,
        catalogSearch?.modelYear,
        onPageSize(articleView),
        pagination.current
      );
    } else {
      searchArticles(
        query,
        filterQuery,
        pagination.current,
        onPageSize(articleView)
      );
    }
  };

  const loadArticleEquivalences = useCallback(async (articleId: number) => {
    setDataLoadingState(true);
    try {
      //  On récupère toutes les équivalences de l'article
      const loadedArticleEquivalences = await findEquivalences(
        articleId,
        100,
        0
      );
      console.log("loadedArticleEquivalences : ", loadedArticleEquivalences);
      setCurrentArticleEquivalenceList(loadedArticleEquivalences);
      // let equivalenceList: IArticleSearchResult[] = [];
      // if (loadedArticleEquivalences && loadArticleEquivalences.length > 0) {
      //   //  On parcours la liste des équivalences trouvées pour aller charger les infos de l'article
      //   //  Sous forme IArticleSearchResult
      //   for (const currentArticleEquivalence of loadedArticleEquivalences) {
      //     if (isArticleEquivalence(currentArticleEquivalence)) {
      //       console.log(
      //         "currentArticleEquivalence est bien de type IArticleEquivalence"
      //       );
      //     } else {
      //       console.log(
      //         "currentArticleEquivalence n'est pas de type IArticleEquivalence"
      //       );
      //     }

      //     const response = await searchArticleById(
      //       currentArticleEquivalence.equivalentArticle.id
      //     );
      //     //  Si l'article a été trouvé
      //     if (response !== undefined && response.content.length > 0) {
      //       let quantity = 1;
      //       if (response.content[0].article.articlePlatforms) {
      //         quantity =
      //           response.content[0].article.articlePlatforms[0].salePackaging;
      //       }
      //       response.content[0].article.quantity = quantity;

      //       if (
      //         response.content[0].article.articlePlatforms &&
      //         response.content[0].article.articlePlatforms.length > 0
      //       ) {
      //         response.content[0].article.quantity =
      //           response.content[0].article.articlePlatforms[0].salePackaging;
      //         //  On va chercher les dispo de chaque article
      //         const articleWithAvailabilities = await checkArticleAvailability(
      //           undefined,
      //           response.content[0].article
      //         );
      //         //  On forme les données complètes sous forme IArticleSearchResult
      //         const newArticle = {
      //           ...response.content[0],
      //           article: {
      //             ...response.content[0].article,
      //             moreAvailabilitiesShow:
      //               articleWithAvailabilities.moreAvailabilitiesShow,
      //             listAvailabilities:
      //               articleWithAvailabilities.listAvailabilities,
      //             availability: articleWithAvailabilities.availability,
      //             moreAvailabilities:
      //               articleWithAvailabilities.moreAvailabilities,
      //           },
      //         };
      //         console.log("newArticle : ", newArticle);
      //         if (isArticleEquivalence(newArticle)) {
      //           console.log("newArticle est bien de type IArticleEquivalence");
      //         } else {
      //           console.log("newArticle n'est pas de type IArticleEquivalence");
      //         }
      //         equivalenceList.push(newArticle);
      //       }
      //     }
      //   }
      //   // Mise à jour du state qui contient les équivalences
      //   setCurrentArticleEquivalenceList(equivalenceList);
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoadingState(false);
    }
  }, []);

  const onSelectEquivalence = useCallback(
    async (articleId: number) => {
      setDataLoadingState(true);
      try {
        const response = await getArticleById(articleId);
        if (response !== undefined && response.content.length > 0) {
          let quantity = 1;
          if (response.content[0].article.articlePlatforms) {
            quantity =
              response.content[0].article.articlePlatforms[0].salePackaging;
          }

          response.content[0].article.quantity = quantity;

          setSelectedArticle(undefined);
          setSelectedArticleSearchResultState(undefined);
          setDataState(response);
        } else {
          message.error("Cet article n'est pas géré.");
        }
      } finally {
        setDataLoadingState(false);
      }
    },
    [setSelectedArticle]
  );

  const onSelectArticle = useCallback(
    async (articleSearchResult: IArticleSearchResult) => {
      await loadArticleEquivalences(articleSearchResult.article.id);
      setSelectedArticleSearchResultState(articleSearchResult);
      setSelectedArticle(articleSearchResult.article);
    },
    [loadArticleEquivalences, setSelectedArticle]
  );

  // const onSelectedArticle = (articleSearchResult: IArticleSearchResult) => {
  //   setSelectedArticle(articleSearchResult.article);
  // };

  const showPlatformList: boolean =
    sessionStorage.getItem("showPlatformList") === "false" ? false : true;

  const columns: ColumnProps<IArticleSearchResult>[] = [
    {
      title: "Référence",
      dataIndex: "article.reference",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          <Button type="link" onClick={() => onSelectArticle(item)}>
            {item.article.reference}
          </Button>
        </div>
      ),
      width: 120,
    },
    {
      title: "Infos",
      dataIndex: "infos",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined && (
            <div>
              <Tag
                color={getAvailabilityColor(
                  item.article.availability.state,
                  item.article.availability.locallyAvailable,
                  item.article.availability.deliveredAt,
                  item.article.availability.quantityBackorder,
                  item.article.availability.longerAvailabilityAlert,
                  item.article.availability.platform.id
                )}
              >
                {getAvailabilityText(
                  item.article.availability.state,
                  item.article.availability.deliveredAt,
                  item.article.availability.quantityBackorder,
                  item.article.availability.longerAvailabilityAlert,
                  undefined
                )}
              </Tag>
              {isSupplierAvailability(
                item.article.availability.quantityBackorder
              )}
              <br />
            </div>
          )}
          <Text strong>Fabricant :</Text> {item.article.manufacturer.name}
          <br />
          <Text strong>EAN(s) :</Text> {getEans(item.article.eans)}
          <br />
          {item.article.availability !== undefined && (
            <div>
              <Text strong>Date de livraison : </Text>
              {formatDeliveredAt(item.article.availability.deliveredAt)}
              <br />
              {showPlatformList &&
                item.article.availability.locallyAvailable && (
                  <span>
                    <Text strong>Provenance : </Text>{" "}
                    {item.article.availability.description}
                  </span>
                )}
              {showPlatformList &&
                !item.article.availability.locallyAvailable && (
                  <span>
                    <Text strong>Provenance : </Text>{" "}
                    <Text strong type="danger">
                      <WarningOutlined /> Autre plateforme
                    </Text>
                  </span>
                )}
            </div>
          )}
        </div>
      ),
      width: 300,
    },
    {
      title: "Prix unitaire € HT",
      dataIndex: "article.availability.grossUnitPrice",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? numberFormat(item.article.availability.grossUnitPrice, 2)
            : ""}
        </div>
      ),
      align: "right",
      width: 110,
    },
    {
      title: "Remise % ",
      dataIndex: "article.availability.discount",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? numberFormat(item.article.availability.discount, 2)
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Vendu par",
      dataIndex: "article.availability.salePackaging",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? item.article.availability.salePackaging
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Consigne",
      dataIndex: "article.availability.consignmentPrice",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? numberFormat(item.article.availability.consignmentPrice, 2)
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Plateforme",
      dataIndex: "platform",
      render: (text: string, item: IArticleSearchResult) => (
        <Skeleton loading={item.article.loading} active>
          {item.article.availability !== undefined && (
            <ArticleWishlistForm
              item={item.article}
              onSelectPlatform={(value: any) =>
                onChangePlatform(value, item.article)
              }
              onChangeQuantity={onChangeQuantity}
            />
          )}
          {item.article.availability === undefined && (
            <Text type="warning">
              Impossible d'obtenir la disponibilité depuis la plateforme.
            </Text>
          )}
        </Skeleton>
      ),
      width: showPlatformList ? 350 : 200,
    },
    {
      title: "Montant net € HT",
      dataIndex: "article.availability.netAmount",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? numberFormat(item.article.availability.netAmount, 2)
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Montant € TTC",
      dataIndex: "article.availability.netAmountInclTaxes",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? numberFormat(item.article.availability.netAmountInclTaxes, 2)
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
  ];

  // Mise à jour de la liste des data avec la nouvelle valeur "bookmark"
  // d'un article
  const onUpdateBookmarkToItemList = (item: IArticleSearchResult) => {
    setDataState((prevState) => {
      const updatedContent = prevState.content.map(
        (element: IArticleSearchResult) =>
          element.article.id === item.article.id
            ? { ...element, bookmarked: !element.bookmarked }
            : element
      );
      return { ...prevState, content: updatedContent };
    });
  };

  useEffect(() => {
    switch (typeSearch) {
      //query search
      case 1:
        searchArticles(query, filterQuery, 0, onPageSize(articleView));
        break;
      //catalog F1
      case 3:
        if (catalogSearch?.queryType === "vehicleNode") {
          searchCatalogArticles(
            catalogSearch.level3Id,
            catalogSearch.level5Id,
            catalogSearch.modelYear,
            onPageSize(articleView),
            0
          );
        }

        if (catalogSearch?.queryType === "characteristic") {
          searchCatalogArticles(
            catalogSearch.level3Id,
            catalogSearch.level5Id,
            undefined,
            onPageSize(articleView),
            0
          );
        }

        if (catalogSearch?.queryType === "family") {
          searchCatalogArticles(
            undefined,
            catalogSearch.level2Id,
            catalogSearch.modelYear,
            onPageSize(articleView),
            0
          );
        }
        break;
      //ETAI search
      case 4:
        if (dataProps) {
          searchArticlesFromPreviousList(dataProps);
        }
        break;
    }
  }, [
    articleView,
    catalogSearch.level2Id,
    catalogSearch.level3Id,
    catalogSearch.level5Id,
    catalogSearch.modelYear,
    catalogSearch?.queryType,
    dataProps,
    filterQuery,
    onPageSize,
    query,
    searchArticles,
    searchArticlesFromPreviousList,
    searchCatalogArticles,
    typeSearch,
  ]);

  return (
    <div>
      <BasketContext.Consumer>
        {(value) => (
          <>
            {advancedQuery && typeSearch === 1 && (
              <AdvancedSearch
                onSearch={onFilter}
                totalRecordFound={dataState.totalElements}
              />
            )}

            <div style={{ width: "100%" }} id="itemsDiv">
              {selectedArticle === undefined && (
                <>
                  {!dataLoadingState && (
                    <Text style={{ marginBottom: 15, marginTop: 15 }}>
                      Nombre de résultats trouvés : {dataState.totalElements}
                    </Text>
                  )}

                  {value.articleView === "table" && (
                    <Table
                      rowKey="article"
                      loading={dataLoadingState}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      showHeader={true}
                      bordered={true}
                      dataSource={dataState.content}
                      onChange={onChange}
                      pagination={{
                        current: dataState.number + 1,
                        pageSize: dataState.size,
                        total: dataState.totalElements,
                      }}
                    />
                  )}

                  {value.articleView === "list" && (
                    <List
                      loading={dataLoadingState}
                      grid={{ gutter: 8, column: 1 }}
                      dataSource={dataState.content}
                      pagination={{
                        onChange: onChangeList2,
                        current: dataState.pageable.pageNumber + 1,
                        pageSize: dataState.pageable.pageSize,
                        total: dataState.totalElements,
                      }}
                      renderItem={(item: IArticleSearchResult) => (
                        <List.Item>
                          <ArticleCardNew
                            dataLoadingState={dataLoadingState}
                            equivalenceStyle={false}
                            item={item}
                            isModal={false}
                            infosTecdoc={infosTecdocRef}
                            infosCatalogF1={infosCatalogF1}
                            // onChangeSelect={onChangeSelect}
                            // onChangeQuantity={onChangeQuantity}
                            onSelectArticle={onSelectArticle}
                            // setSelectedArticleSearchResult={
                            //   setSelectedArticleSearchResultState
                            // }
                            onSelectEquivalence={onSelectEquivalence}
                            // setSelectedArticle={setSelectedArticle}
                            openModalAccount={onOpenModalAccount}
                            showArticleEquivalence={true}
                            onUpdateBookmarkToItemList={
                              onUpdateBookmarkToItemList
                            }
                          />
                        </List.Item>
                      )}
                    />
                  )}
                </>
              )}

              {selectedArticle !== undefined &&
                selectedArticleSearchResultState !== undefined && (
                  <ArticleCard2
                    dataLoadingState={dataLoadingState}
                    item={selectedArticleSearchResultState}
                    isModal={false}
                    infosTecdoc={infosTecdocRef}
                    infosCatalogF1={infosCatalogF1}
                    showArticleEquivalence={false}
                    equivalenceStyle={true}
                    onSelectArticle={onSelectArticle}
                    onSelectEquivalence={onSelectEquivalence}
                    openModalAccount={onOpenModalAccount}
                    onUpdateBookmarkToItemList={onUpdateBookmarkToItemList}
                  />
                )}
              {selectedArticle !== undefined &&
                selectedArticleSearchResultState !== undefined &&
                currentArticleEquivalenceList.length > 0 && (
                  <>
                    <Divider
                      orientation="center"
                      style={{ borderColor: "#1890ff", color: "#1890ff" }}
                    >
                      LES EQUIVALENCES : {currentArticleEquivalenceList.length}{" "}
                      résultats trouvés
                    </Divider>
                    {value.articleView === "list" && (
                      <List
                        loading={dataLoadingState}
                        grid={{ gutter: 8, column: 1 }}
                        dataSource={currentArticleEquivalenceList}
                        pagination={false}
                        renderItem={(item: IArticleEquivalence) => (
                          <List.Item>
                            <ArticleCard2
                              dataLoadingState={dataLoadingState}
                              item={item}
                              isModal={false}
                              infosTecdoc={infosTecdocRef}
                              infosCatalogF1={infosCatalogF1}
                              showArticleEquivalence={false}
                              equivalenceStyle={false}
                              onSelectArticle={onSelectArticle}
                              onSelectEquivalence={onSelectEquivalence}
                              openModalAccount={onOpenModalAccount}
                              onUpdateBookmarkToItemList={
                                onUpdateBookmarkToItemList
                              }
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </>
                )}
              {selectedArticle !== undefined &&
                selectedArticleSearchResultState !== undefined &&
                currentArticleEquivalenceList.length === 0 && (
                  <Divider
                    orientation="center"
                    style={{ borderColor: "#1890ff", color: "#1890ff" }}
                  >
                    AUCUNE EQUIVALENCE
                  </Divider>
                )}
            </div>
          </>
        )}
      </BasketContext.Consumer>

      <Modal
        open={openModalAccountState}
        destroyOnClose
        closable={false}
        title="Demande d'ouverture d'un compte"
        style={{ top: 20 }}
        width={450}
        bodyStyle={{ height: 650 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <OpenAccount closeModal={onCloseModalAccount} />
      </Modal>
    </div>
  );
};
export default ArticleDisplay;

// export default withBasket(ArticleDisplay2);

// import React from "react";
// import { Table, Typography, List, Tag, Skeleton, Button, Modal } from "antd";
// import { ColumnProps } from "antd/lib/table";
// import hash from "object-hash";

// import { withBasket, BasketContext } from "../../Utils/BasketProvider";
// import IArticle from "./IArticle";
// import FlybyUtils from "../../Utils/FlybyUtils";
// import IData from "../../Utils/IData";
// import IArticlePlatforms from "./IArticlePlatforms";
// import ArticleService from "./ArticleService";
// import ArticleUtils from "./ArticleUtils";
// import ArticleCard from "./ArticleCard";
// import IArticleSearchResult from "./IArticleSearchResult";
// import ICatalogSearch from "../catalog/model/ICatalogSearch";
// import ParameterService from "../../Utils/ParameterService";
// import AdvancedSearch from "./AdvancedSearch";
// import IFilterQuery from "../../Utils/IFilterQuery";
// import { WarningOutlined } from "@ant-design/icons";
// import ArticleWishlistForm from "./ArticleWishlistForm";
// import DetailArticle from "./component/DetailArticle";
// import OpenAccount from "../login/OpenAccount";

// const { Text } = Typography;

// class ArticleDisplay extends React.Component<
//   {
//     addArticle: any;
//     setQuery: any;
//     setAdvancedQuery: any;
//     query: string;
//     advancedQuery: boolean;
//     setTypeSearch: any;
//     typeSearch: number;
//     catalogSearch: ICatalogSearch;
//     data: IData;
//     filterQuery: IFilterQuery;
//     articleView: string;
//     selectedArticle: IArticle | undefined;
//     setSelectedArticle: any;
//   },
//   {
//     data: IData;
//     dataLoading: boolean;
//     openModalAccount: boolean;
//   }
// > {
//   private articleUtils: ArticleUtils = new ArticleUtils();
//   private articleService: ArticleService = new ArticleService();
//   private parameterService: ParameterService = new ParameterService();
//   private flybyUtils: FlybyUtils = new FlybyUtils();
//   private pageSizeList: number = 5;
//   private pageSizeTable: number = 10;
//   private infosTecdoc: boolean = false;
//   private infosCatalogF1: boolean = false;

//   private showPlatformList: boolean =
//     sessionStorage.getItem("showPlatformList") === "false" ? false : true;

//   private columns: ColumnProps<IArticleSearchResult>[] = [
//     {
//       title: "Référence",
//       dataIndex: "article.reference",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           <Button type="link" onClick={() => this.onSelectedArticle(item)}>
//             {item.article.reference}
//           </Button>
//         </div>
//       ),
//       width: 120,
//     },
//     {
//       title: "Infos",
//       dataIndex: "infos",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           {item.article.availability !== undefined && (
//             <div>
//               <Tag
//                 color={this.flybyUtils.getAvailabilityColor(
//                   item.article.availability.state,
//                   item.article.availability.locallyAvailable,
//                   item.article.availability.deliveredAt,
//                   item.article.availability.quantityBackorder,
//                   item.article.availability.longerAvailabilityAlert,
//                   item.article.availability.platform.id
//                 )}
//               >
//                 {this.flybyUtils.getAvailabilityText(
//                   item.article.availability.state,
//                   item.article.availability.deliveredAt,
//                   item.article.availability.quantityBackorder,
//                   item.article.availability.longerAvailabilityAlert,
//                   undefined
//                 )}
//               </Tag>
//               {this.flybyUtils.isSupplierAvailability(
//                 item.article.availability.quantityBackorder
//               )}
//               <br />
//             </div>
//           )}
//           <Text strong>Fabricant :</Text> {item.article.manufacturer.name}
//           <br />
//           <Text strong>EAN(s) :</Text>{" "}
//           {this.flybyUtils.getEans(item.article.eans)}
//           <br />
//           {item.article.availability !== undefined && (
//             <div>
//               <Text strong>Date de livraison : </Text>
//               {this.flybyUtils.formatDeliveredAt(
//                 item.article.availability.deliveredAt
//               )}
//               <br />
//               {this.showPlatformList &&
//                 item.article.availability.locallyAvailable && (
//                   <span>
//                     <Text strong>Provenance : </Text>{" "}
//                     {item.article.availability.description}
//                   </span>
//                 )}
//               {this.showPlatformList &&
//                 !item.article.availability.locallyAvailable && (
//                   <span>
//                     <Text strong>Provenance : </Text>{" "}
//                     <Text strong type="danger">
//                       <WarningOutlined /> Autre plateforme
//                     </Text>
//                   </span>
//                 )}
//             </div>
//           )}
//         </div>
//       ),
//       width: 300,
//     },
//     {
//       title: "Prix unitaire € HT",
//       dataIndex: "article.availability.grossUnitPrice",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           {item.article.availability !== undefined
//             ? this.flybyUtils.numberFormat(
//                 item.article.availability.grossUnitPrice,
//                 2
//               )
//             : ""}
//         </div>
//       ),
//       align: "right",
//       width: 110,
//     },
//     {
//       title: "Remise % ",
//       dataIndex: "article.availability.discount",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           {item.article.availability !== undefined
//             ? this.flybyUtils.numberFormat(
//                 item.article.availability.discount,
//                 2
//               )
//             : ""}
//         </div>
//       ),
//       align: "right",
//       width: 105,
//     },
//     {
//       title: "Vendu par",
//       dataIndex: "article.availability.salePackaging",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           {item.article.availability !== undefined
//             ? item.article.availability.salePackaging
//             : ""}
//         </div>
//       ),
//       align: "right",
//       width: 105,
//     },
//     {
//       title: "Consigne",
//       dataIndex: "article.availability.consignmentPrice",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           {item.article.availability !== undefined
//             ? this.flybyUtils.numberFormat(
//                 item.article.availability.consignmentPrice,
//                 2
//               )
//             : ""}
//         </div>
//       ),
//       align: "right",
//       width: 105,
//     },
//     {
//       title: "Plateforme",
//       dataIndex: "platform",
//       render: (text: string, item: IArticleSearchResult) => (
//         <Skeleton loading={item.article.loading} active>
//           {item.article.availability !== undefined && (
//             <ArticleWishlistForm
//               item={item.article}
//               onSelectPlatform={(value: any) =>
//                 this.onChangeSelect(value, item.article)
//               }
//               onChangeQuantity={this.onChangeQuantity}
//             />
//           )}
//           {item.article.availability === undefined && (
//             <Text type="warning">
//               Impossible d'obtenir la disponibilité depuis la plateforme.
//             </Text>
//           )}
//         </Skeleton>
//       ),
//       width: this.showPlatformList ? 350 : 200,
//     },
//     {
//       title: "Montant net € HT",
//       dataIndex: "article.availability.netAmount",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           {item.article.availability !== undefined
//             ? this.flybyUtils.numberFormat(
//                 item.article.availability.netAmount,
//                 2
//               )
//             : ""}
//         </div>
//       ),
//       align: "right",
//       width: 105,
//     },
//     {
//       title: "Montant € TTC",
//       dataIndex: "article.availability.netAmountInclTaxes",
//       render: (text: string, item: IArticleSearchResult) => (
//         <div>
//           {item.article.availability !== undefined
//             ? this.flybyUtils.numberFormat(
//                 item.article.availability.netAmountInclTaxes,
//                 2
//               )
//             : ""}
//         </div>
//       ),
//       align: "right",
//       width: 105,
//     },
//   ];

//   constructor(props: {
//     addArticle: any;
//     setQuery: any;
//     setAdvancedQuery: any;
//     query: string;
//     advancedQuery: boolean;
//     setTypeSearch: any;
//     typeSearch: number;
//     data: IData;
//     catalogSearch: ICatalogSearch;
//     filterQuery: IFilterQuery;
//     articleView: string;
//     selectedArticle: IArticle | undefined;
//     setSelectedArticle: any;
//   }) {
//     super(props);

//     this.state = {
//       data: this.flybyUtils.getEmptyData(),
//       dataLoading: false,
//       openModalAccount: false,
//     };
//   }

//   componentDidMount() {
//     let promise1 = this.parameterService
//       .findValue("TECDOC_SHOW_INFOS")
//       .then((value: string) => {
//         if (value !== undefined) {
//           return JSON.parse(value);
//         } else {
//           return false;
//         }
//       });

//     let promise2 = this.parameterService
//       .findValue("CATALOG_F1")
//       .then((value: string) => {
//         if (value !== undefined) {
//           return JSON.parse(value);
//         } else {
//           return false;
//         }
//       });

//     Promise.all([promise1, promise2]).then((result: any) => {
//       this.infosTecdoc = result[0];
//       this.infosCatalogF1 = result[1];

//       switch (this.props.typeSearch) {
//         //query search
//         case 1:
//           this.searchArticles(
//             this.props.query,
//             this.props.filterQuery,
//             this.pageSize(this.props.articleView),
//             0
//           );
//           break;
//         //catalog F1
//         case 3:
//           if (this.props.catalogSearch.queryType === "vehicleNode") {
//             this.searchCatalogArticles(
//               this.props.catalogSearch.level3Id,
//               this.props.catalogSearch.level5Id,
//               this.props.catalogSearch.modelYear,
//               this.pageSize(this.props.articleView),
//               0
//             );
//           }

//           if (this.props.catalogSearch.queryType === "characteristic") {
//             this.searchCatalogArticles(
//               this.props.catalogSearch.level3Id,
//               this.props.catalogSearch.level5Id,
//               undefined,
//               this.pageSize(this.props.articleView),
//               0
//             );
//           }

//           if (this.props.catalogSearch.queryType === "family") {
//             this.searchCatalogArticles(
//               undefined,
//               this.props.catalogSearch.level2Id,
//               this.props.catalogSearch.modelYear,
//               this.pageSize(this.props.articleView),
//               0
//             );
//           }
//           break;
//         //ETAI search
//         case 4:
//           this.searchArticlesFromPreviousList(this.props.data);
//           break;
//       }
//     });
//   }

//   componentWillReceiveProps(newProps: {
//     addArticle: any;
//     setQuery: any;
//     setAdvancedQuery: any;
//     query: string;
//     advancedQuery: boolean;
//     setTypeSearch: any;
//     typeSearch: number;
//     data: IData;
//     catalogSearch: ICatalogSearch;
//     filterQuery: IFilterQuery;
//     articleView: string;
//     setArticleView: any;
//     selectedArticle: IArticle | undefined;
//     setSelectedArticle: any;
//   }) {
//     switch (newProps.typeSearch) {
//       case 1:
//         //|| (newProps.query === this.props.query && hash.MD5(newProps.filterQuery) !== hash.MD5(this.props.filterQuery))
//         if (
//           newProps.query !== this.props.query ||
//           newProps.articleView !== this.props.articleView ||
//           hash.MD5(this.props.filterQuery) !== hash.MD5(newProps.filterQuery)
//         ) {
//           this.searchArticles(
//             newProps.query,
//             newProps.filterQuery,
//             this.pageSize(newProps.articleView),
//             0
//           );
//         }
//         break;

//       case 3:
//         if (
//           newProps.catalogSearch.queryType === "vehicleNode" &&
//           (hash.MD5(newProps.catalogSearch) !==
//             hash.MD5(this.props.catalogSearch) ||
//             newProps.articleView !== this.props.articleView)
//         ) {
//           this.searchCatalogArticles(
//             newProps.catalogSearch.level3Id,
//             newProps.catalogSearch.level5Id,
//             newProps.catalogSearch.modelYear,
//             this.pageSize(newProps.articleView),
//             0
//           );
//         }

//         if (
//           newProps.catalogSearch.queryType === "characteristic" &&
//           (hash.MD5(newProps.catalogSearch) !==
//             hash.MD5(this.props.catalogSearch) ||
//             newProps.articleView !== this.props.articleView)
//         ) {
//           this.searchCatalogArticles(
//             newProps.catalogSearch.level3Id,
//             newProps.catalogSearch.level5Id,
//             undefined,
//             this.pageSize(newProps.articleView),
//             0
//           );
//         }

//         if (
//           newProps.catalogSearch.queryType === "family" &&
//           (hash.MD5(newProps.catalogSearch) !==
//             hash.MD5(this.props.catalogSearch) ||
//             newProps.articleView !== this.props.articleView)
//         ) {
//           this.searchCatalogArticles(
//             undefined,
//             newProps.catalogSearch.level2Id,
//             newProps.catalogSearch.modelYear,
//             this.pageSize(newProps.articleView),
//             0
//           );
//         }

//         break;

//       case 4:
//         if (
//           hash.MD5(newProps.data) !== hash.MD5(this.props.data) ||
//           newProps.typeSearch !== this.props.typeSearch ||
//           newProps.articleView !== this.props.articleView
//         ) {
//           this.searchArticlesFromPreviousList(newProps.data);
//         }
//         break;
//     }
//   }

//   render() {
//     return (
//       <div>
//         <BasketContext.Consumer>
//           {(value) => (
//             <>
//               {this.props.advancedQuery && this.props.typeSearch === 1 && (
//                 <AdvancedSearch
//                   onSearch={this.onFilter}
//                   totalRecordFound={this.state.data.totalElements}
//                 />
//               )}

//               <div style={{ width: "100%" }} id="itemsDiv">
//                 {this.props.selectedArticle === undefined && (
//                   <>
//                     {!this.props.advancedQuery && (
//                       <Text style={{ marginBottom: 15 }}>
//                         Nombre de résultats trouvés :{" "}
//                         {this.state.data.totalElements}
//                       </Text>
//                     )}

//                     {value.articleView === "table" && (
//                       <Table
//                         rowKey="article"
//                         loading={this.state.dataLoading}
//                         style={{ overflowX: "auto" }}
//                         columns={this.columns}
//                         showHeader={true}
//                         bordered={true}
//                         dataSource={this.state.data.content}
//                         onChange={this.onChange}
//                         pagination={{
//                           current: this.state.data.number + 1,
//                           pageSize: this.state.data.size,
//                           total: this.state.data.totalElements,
//                         }}
//                       />
//                     )}

//                     {value.articleView === "list" && (
//                       <List
//                         loading={this.state.dataLoading}
//                         grid={{ gutter: 8, column: 1 }}
//                         dataSource={this.state.data.content}
//                         pagination={{
//                           onChange: this.onChangeList,
//                           current: this.state.data.number + 1,
//                           pageSize: this.state.data.size,
//                           total: this.state.data.totalElements,
//                         }}
//                         renderItem={(item: IArticleSearchResult) => (
//                           <List.Item>
//                             <ArticleCard
//                               item={item}
//                               onChangeSelect={this.onChangeSelect}
//                               onChangeQuantity={this.onChangeQuantity}
//                               onSelectEquivalence={this.onSelectEquivalence}
//                               isModal={false}
//                               infosTecdoc={this.infosTecdoc}
//                               infosCatalogF1={this.infosCatalogF1}
//                               openModalAccount={this.onOpenModalAccount}
//                             />
//                           </List.Item>
//                         )}
//                       />
//                     )}
//                   </>
//                 )}

//                 {this.props.selectedArticle !== undefined && (
//                   <DetailArticle
//                     article={this.props.selectedArticle}
//                     onChangeSelect={this.onChangeSelect}
//                     onChangeQuantity={this.onChangeQuantity}
//                   />
//                 )}
//               </div>
//             </>
//           )}
//         </BasketContext.Consumer>

//         <Modal
//           open={this.state.openModalAccount}
//           destroyOnClose
//           closable={false}
//           title="Demande d'ouverture d'un compte"
//           style={{ top: 20 }}
//           width={450}
//           bodyStyle={{ height: 650 }}
//           okButtonProps={{ hidden: true }}
//           cancelButtonProps={{ hidden: true }}
//         >
//           <OpenAccount closeModal={this.onCloseModalAccount} />
//         </Modal>
//       </div>
//     );
//   }

//   private onOpenModalAccount = () => {
//     this.setState({
//       openModalAccount: true,
//     });
//   };

//   private onCloseModalAccount = () => {
//     this.setState({
//       openModalAccount: false,
//     });
//   };

//   private onChangeList = (pageNumber: number, pageSize?: number) => {
//     if (this.props.typeSearch === 3) {
//       this.searchCatalogArticles(
//         this.props.catalogSearch.level3Id,
//         this.props.catalogSearch.level5Id,
//         this.props.catalogSearch.modelYear,
//         this.pageSize(this.props.articleView),
//         pageNumber - 1
//       );
//     } else {
//       this.searchArticles(
//         this.props.query,
//         this.props.filterQuery,
//         this.pageSize(this.props.articleView),
//         pageNumber - 1
//       );
//     }
//   };

//   private onFilter = () => {
//     this.searchArticles(
//       this.props.query,
//       this.props.filterQuery,
//       this.pageSize(this.props.articleView),
//       0
//     );
//   };

//   private searchArticlesFromPreviousList = (data: IData) => {
//     this.setState({
//       dataLoading: true,
//     });

//     if (data.size === 0) data.size = this.pageSize(this.props.articleView);

//     this.search("", data);
//   };

//   private onChangeQuantity = (
//     quantity: number,
//     platformId: number,
//     item: IArticle
//   ) => {
//     this.articleUtils.onChangeQuantity(quantity, platformId, item, this);
//   };

//   private onChangeSelect = (platformId: number, item: IArticle) => {
//     this.articleUtils.onChangeSelect(platformId, item, this);
//   };

//   private onChange = (
//     pagination: any,
//     filters: Record<never, string[]>,
//     sorter: any,
//     extra: any
//   ) => {
//     pagination = this.flybyUtils.getCurrentPage(pagination);

//     if (this.props.typeSearch === 3) {
//       this.searchCatalogArticles(
//         this.props.catalogSearch.level3Id,
//         this.props.catalogSearch.level5Id,
//         this.props.catalogSearch.modelYear,
//         this.pageSize(this.props.articleView),
//         pagination.current
//       );
//     } else {
//       this.searchArticles(
//         this.props.query,
//         this.props.filterQuery,
//         this.pageSize(this.props.articleView),
//         pagination.current
//       );
//     }
//   };

//   private onSelectEquivalence = (articleId: number) => {
//     this.setState({
//       dataLoading: true,
//     });

//     this.articleService.searchArticleById(articleId).then((result: any) => {
//       if (result !== undefined) {
//         let quantity = 1;
//         if (result.content[0].article.articlePlatforms) {
//           quantity =
//             result.content[0].article.articlePlatforms[0].salePackaging;
//         }

//         result.content[0].article.loading = true;
//         result.content[0].article.quantity = quantity;
//         result.content[0].article.equivalence = {
//           loading: true,
//           list: [],
//         };

//         this.articleUtils.checkArticleAvailability(
//           undefined,
//           result.content[0].article,
//           false,
//           this
//         );
//         this.articleUtils.findEquivalences(
//           result.content[0].article,
//           true,
//           this
//         );

//         this.setState({
//           data: result,
//           dataLoading: false,
//         });
//       }
//     });
//   };

//   private searchArticles = (
//     query: string,
//     filterQuery: IFilterQuery,
//     pageSize: number | undefined,
//     current: number | undefined
//   ) => {
//     this.setState({
//       dataLoading: true,
//     });

//     this.articleService
//       .searchs(pageSize, current, filterQuery, query)
//       .then((data: any) => {
//         if (data !== undefined) {
//           this.search(query, data);
//           this.props.setTypeSearch(1);
//         }
//       });
//   };

//   private searchCatalogArticles = (
//     vehicleTypeId: number | undefined,
//     familyId: number | undefined,
//     modelYear: number | undefined,
//     pageSize: number | undefined,
//     current: number | undefined
//   ) => {
//     this.setState({
//       dataLoading: true,
//     });

//     this.articleService
//       .searchCatalogArticle(
//         vehicleTypeId,
//         familyId,
//         modelYear,
//         pageSize,
//         current
//       )
//       .then((data: any) => {
//         if (data !== undefined) {
//           this.search("", data);
//           this.props.setTypeSearch(3);
//           document?.getElementById("itemsDiv")?.scrollIntoView();
//         }
//       });
//   };

//   private search = (query: string, data: any) => {
//     if (data !== undefined && data.content !== undefined) {
//       data.content.forEach((articleSearchResult: IArticleSearchResult) => {
//         const { article } = articleSearchResult;

//         let articlePlatform: IArticlePlatforms = article.articlePlatforms[0];

//         if (articlePlatform !== undefined) {
//           article.loading = true;
//           article.quantity = articlePlatform.salePackaging;
//           article.equivalence = {
//             loading: true,
//             list: [],
//           };

//           if (articleSearchResult.known) {
//             this.articleUtils.checkArticleAvailability(
//               undefined,
//               article,
//               false,
//               this
//             );
//             this.articleUtils.findEquivalences(article, true, this);
//             document?.getElementById("itemsDiv")?.scrollIntoView();
//           }
//         }
//       });

//       this.setState({
//         data: data,
//         dataLoading: false,
//       });

//       this.props.setQuery(query);
//     } else {
//       this.setState({
//         dataLoading: false,
//       });
//     }
//   };

//   private pageSize = (articleView: string) => {
//     if (articleView === "table") {
//       return this.pageSizeTable;
//     }
//     if (articleView === "list") {
//       return this.pageSizeList;
//     }

//     return 10;
//   };

//   private onSelectedArticle = (articleSearchResult: IArticleSearchResult) => {
//     this.props.setSelectedArticle(articleSearchResult.article);
//   };
// }

// export default withBasket(ArticleDisplay);
