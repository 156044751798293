import { Button, Col, Row, Skeleton, Tag, Typography } from "antd";
import {
  getAvailabilityColor,
  getAvailabilityText,
  getEans,
  isSupplierAvailability,
  numberFormat,
} from "../../Utils/FlybyUtilsFunctional";
import IArticle from "./IArticle";
import { WarningOutlined } from "@ant-design/icons";
import ArticleAvailabilityRequestInformation from "./component/ArticleAvailabilityRequestInformation";
import { useCallback, useEffect, useState } from "react";
import parse from "html-react-parser";
import { findParameterValueByName } from "../../Utils/ParameterServiceFunctional";

interface ArticleAvailabilityProps {
  articleState: IArticle;
  loadingState: boolean;
  showAvailability: boolean;
  showManufacturer: boolean;
  currentSupplierAvailabilityStatus: string;
  currentSupplierAvailabilityLabel: string;
  currentSupplierAvailabilityWarningEnabled: boolean;
  openModalAccount?: () => void;
}

const ArticleAvailability2 = ({
  articleState,
  loadingState,
  showAvailability,
  showManufacturer,
  currentSupplierAvailabilityStatus,
  currentSupplierAvailabilityLabel,
  currentSupplierAvailabilityWarningEnabled,
  openModalAccount,
}: ArticleAvailabilityProps): React.ReactElement => {
  const { Text } = Typography;

  const USER_ROLE = sessionStorage.getItem("role");

  const accessToken = sessionStorage.getItem("accessToken");

  const [availabilityText1, setAvailabilityText1] = useState<string>(
    "Pour connaitre le prix et la disponibilité vous devez avoir un compte client"
  );
  const [availabilityText2, setAvailabilityText2] = useState<string>(
    "Contactez nous par mail"
  );

  const parameterPlatformList = sessionStorage.getItem("showPlatformList");
  const showPlatformList = parameterPlatformList
    ? JSON.parse(parameterPlatformList)
    : undefined;

  const findParameter = useCallback(async () => {
    try {
      const response = await findParameterValueByName(
        "GUEST_AVAILABILITY_TEXT"
      );
      if (response) {
        const text = JSON.parse(response);
        setAvailabilityText1(text.text1);
        setAvailabilityText2(text.text2);
      }
    } catch {}
  }, []);

  useEffect(() => {
    findParameter();
  }, [accessToken, findParameter]);

  return (
    <Row style={{ marginLeft: 10, marginRight: 10 }} gutter={8}>
      <Col span={12}>
        <Skeleton loading={loadingState} active>
          {articleState?.availability && (
            <>
              <Tag
                color={getAvailabilityColor(
                  currentSupplierAvailabilityStatus &&
                    articleState.availability.quantityBackorder > 0 &&
                    articleState.availability.state === "AVAILABLE"
                    ? currentSupplierAvailabilityStatus
                    : articleState.availability.state,
                  articleState.availability.locallyAvailable,
                  articleState.availability.deliveredAt,
                  articleState.availability.quantityBackorder,
                  articleState.availability.longerAvailabilityAlert,
                  articleState.availability.platform.id
                )}
              >
                {getAvailabilityText(
                  articleState.availability.state,
                  articleState.availability.deliveredAt,
                  articleState.availability.quantityBackorder,
                  articleState.availability.longerAvailabilityAlert,
                  currentSupplierAvailabilityLabel &&
                    articleState.availability.quantityBackorder > 0 &&
                    articleState.availability.state === "AVAILABLE"
                    ? currentSupplierAvailabilityLabel
                    : undefined
                )}
              </Tag>
              {isSupplierAvailability(
                currentSupplierAvailabilityWarningEnabled &&
                  articleState.availability.quantityBackorder > 0 &&
                  articleState.availability.state === "AVAILABLE"
                  ? 10
                  : !currentSupplierAvailabilityWarningEnabled &&
                    articleState.availability.quantityBackorder > 0 &&
                    articleState.availability.state === "AVAILABLE"
                  ? 0
                  : articleState.availability.quantityBackorder
              )}
              <br />
            </>
          )}
          <Text strong>Référence :</Text> {articleState?.reference}
          <br />
          <Text strong>EAN(s) :</Text>{" "}
          {articleState?.eans ? getEans(articleState?.eans) : undefined}
          <br />
          {showManufacturer && (
            <>
              <Text strong>Fabricant :</Text> {articleState?.manufacturer.name}
              <br />
            </>
          )}
        </Skeleton>
      </Col>
      <Col span={12}>
        <Skeleton loading={loadingState} active>
          {articleState?.availability !== undefined &&
            USER_ROLE !== "ROLE_GUEST" && (
              <div key={articleState?.id}>
                <Text strong>Prix unitaire HT : </Text>
                {numberFormat(articleState.availability.grossUnitPrice, 2)}
                {" €"}
                <br />
                {articleState.availability.discount !== 0 && (
                  <>
                    <Text strong>Remise : </Text>
                    {articleState.availability.discountAmount}
                    {" € soit "}
                    <Tag color="#FF0000">
                      {articleState.availability.discount}
                      {" % "}
                    </Tag>
                    <br />
                  </>
                )}
                <Text strong>Prix unitaire net : </Text>
                {numberFormat(articleState.availability.netUnitPrice, 2)}
                {" €"}
                <br />
                <Text strong>Vendu par : </Text>
                {articleState.availability.salePackaging}
                <br />
                <Text strong>Date de livraison : </Text>
                <ArticleAvailabilityRequestInformation
                  articleId={articleState.id}
                  date={articleState.availability.deliveredAt}
                />
                <br />
                {showPlatformList &&
                  articleState.availability.locallyAvailable && (
                    <span>
                      <Text strong>Provenance : </Text>
                      {articleState.availability.description}
                      <br />
                    </span>
                  )}
                {showPlatformList &&
                  !articleState.availability.locallyAvailable && (
                    <span>
                      <Text strong>Provenance : </Text>
                      <Text strong type="danger">
                        <WarningOutlined /> Autre plateforme
                      </Text>
                      <br />
                    </span>
                  )}
                {articleState.availability.consignmentPrice > 0 && (
                  <span>
                    <Text strong>Consigne : </Text>
                    {numberFormat(
                      articleState.availability.consignmentPrice,
                      2
                    )}
                    <br />
                  </span>
                )}
                {showAvailability && (
                  <span>
                    <Text strong>Montant net € HT : </Text>
                    {numberFormat(articleState.availability.netAmount, 2)}
                    <br />
                  </span>
                )}
              </div>
            )}
          {articleState.availability === undefined &&
            USER_ROLE !== "ROLE_GUEST" && (
              <div key={articleState.id}>
                <Row>
                  <Col flex={"50px"}>
                    <Text type="warning">
                      <WarningOutlined style={{ fontSize: 36 }} />
                    </Text>
                  </Col>
                  <Col flex={"200px"}>
                    <Text type="warning">
                      Impossible d'obtenir la disponibilité depuis la
                      plateforme.
                    </Text>
                  </Col>
                </Row>
              </div>
            )}
          {USER_ROLE === "ROLE_GUEST" && (
            <div key={articleState?.id}>
              <Text type="secondary">{parse(availabilityText1)}</Text>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={openModalAccount}
              >
                {parse(availabilityText2)}
              </Button>
            </div>
          )}
        </Skeleton>
      </Col>
    </Row>
  );
};

export default ArticleAvailability2;
