import { Row, Col, Divider, Tag, Typography } from "antd";
import ISalesOrder from "./ISalesOrder";
import IWishlistTotal from "../home/IWishlistTotal";
import FlybyUtils from "../../Utils/FlybyUtils";
import IWishlist from "../home/IWishlist";
import IWishlistArticle from "../home/IWishlistArticle";
import { WarningOutlined } from "@ant-design/icons";
import { DefaultParameter } from "../../Utils/MarketParameterContext";

const flybyUtils: FlybyUtils = new FlybyUtils();
const { Text } = Typography;
const showPlatformList: boolean =
  sessionStorage.getItem("showPlatformList") === "false" ? false : true;

export const SalesOrderPreviewPlatformArticle = (props: {
  item: IWishlistArticle;
  parameters: DefaultParameter;
}) => {
  return (
    <div key={props.item.id} style={{ width: "100%" }}>
      <Row key={"item" + props.item.id} style={{ padding: 5 }}>
        <Col span={3} style={{ paddingTop: 6 }}>
          {props.item.article.reference}
        </Col>
        <Col span={4} style={{ paddingLeft: 5, paddingTop: 6 }}>
          {props.parameters.SHOW_MANUFACTURER ? props.item.article.name : ""}
        </Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}>
          {props.item.article.manufacturer.name}
        </Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}>
          <Tag
            color={flybyUtils.getAvailabilityColor(
              props.item.state,
              props.item.locallyAvailable,
              props.item.deliveredAt,
              props.item.quantityBackorder,
              props.item.longerAvailabilityAlert
            )}
          >
            {flybyUtils.getAvailabilityText(
              props.item.state,
              props.item.deliveredAt,
              props.item.quantityBackorder,
              props.item.longerAvailabilityAlert,
              undefined
            )}
          </Tag>
          <br />
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {props.item.quantity}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.grossUnitPrice, 2)}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.discount, 2)}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.netUnitPrice, 2)}
        </Col>
        <Col
          span={2}
          style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
        >
          {flybyUtils.numberFormat(props.item.netAmount, 2)}
        </Col>
        <Col span={1} style={{ paddingLeft: 5, textAlign: "right" }}></Col>
      </Row>

      <Row
        key={"availabilityDescription" + props.item.id}
        style={
          props.item.consignmentPrice > 0
            ? { padding: 5 }
            : {
                padding: 5,
                borderBottom: "solid",
                borderBottomColor: "#e8e8e8",
                borderBottomWidth: "1px",
              }
        }
      >
        <Col span={3} style={{ paddingTop: 6 }}></Col>
        <Col
          span={10}
          style={{
            paddingLeft: 5,
            paddingTop: 6,
            borderTop: "dashed",
            borderTopColor: "#e8e8e8",
            borderTopWidth: "1px",
          }}
        >
          <Text strong>EAN(s) : </Text>
          {flybyUtils.getFirstEan(props.item.article.eans)}
          <br />
          {showPlatformList && props.item.locallyAvailable && (
            <span>
              <Text strong>Provenance : </Text>
              {props.item.availabilityDescription}
              <br />
            </span>
          )}
          {showPlatformList && !props.item.locallyAvailable && (
            <span>
              <Text strong>Provenance : </Text>
              <Text strong type="danger">
                <WarningOutlined /> Autre plateforme
              </Text>
              <br />
            </span>
          )}
        </Col>
        <Col
          span={11}
          style={{
            paddingLeft: 5,
            paddingTop: 6,
            borderTop: "dashed",
            borderTopColor: "#e8e8e8",
            borderTopWidth: "1px",
          }}
        ></Col>
      </Row>

      {props.item.consignmentPrice > 0 && (
        <Row
          key={"consignment" + props.item.id}
          style={{
            padding: 5,
            borderBottom: "solid",
            borderBottomColor: "#e8e8e8",
            borderBottomWidth: "1px",
          }}
        >
          <Col span={3} style={{ paddingTop: 6 }}></Col>
          <Col
            span={4}
            style={{
              paddingLeft: 5,
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            Consigne
          </Col>
          <Col
            span={3}
            style={{
              paddingLeft: 5,
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={3}
            style={{
              paddingLeft: 5,
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            {props.item.quantity}
          </Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            {flybyUtils.numberFormat(props.item.consignmentPrice, 2)}
          </Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
          <Col
            span={2}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              paddingTop: 6,
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          >
            {flybyUtils.numberFormat(props.item.consignmentAmountExclTaxes, 2)}
          </Col>
          <Col
            span={1}
            style={{
              paddingLeft: 5,
              textAlign: "right",
              borderTop: "dashed",
              borderTopColor: "#e8e8e8",
              borderTopWidth: "1px",
            }}
          ></Col>
        </Row>
      )}
    </div>
  );
};

export const SalesOrderPreviewPlatformHeader = (props: {
  wishlist: IWishlist;
}) => {
  return (
    <Row
      style={{
        fontWeight: "bold",
        background: "#FAFAFA",
        padding: 5,
        borderBottom: "solid",
        borderBottomColor: "#e8e8e8",
        borderBottomWidth: "1px",
      }}
    >
      <Col span={3} style={{ paddingTop: 6 }}>
        {props.wishlist.name}
      </Col>

      <Col span={4} style={{ paddingLeft: 5, paddingTop: 6 }}>
        Référence : {props.wishlist.reference}
      </Col>
      <Col span={5} style={{ paddingLeft: 5, paddingTop: 6 }}>
        Commentaire : {props.wishlist.reference2}
      </Col>

      <Col span={12} style={{ paddingLeft: 5, paddingTop: 6 }}>
        Mode d'expédition : {props.wishlist.shippingMethod.name}
      </Col>
    </Row>
  );
};

export const SalesOrderPreviewFooter = (props: {
  wishlistTotal: IWishlistTotal;
}) => {
  return (
    <Row
      style={{
        fontWeight: "bold",
        background: "#ECECEC",
        padding: 5,
        borderBottom: "solid",
        borderBottomColor: "#e8e8e8",
        borderBottomWidth: "1px",
      }}
    >
      <Col span={3} style={{ paddingTop: 5 }}>
        TOTAL
      </Col>
      <Col span={4} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
      <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
      <Col
        span={3}
        style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
      ></Col>
      <Col
        span={2}
        style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
      >
        {props.wishlistTotal.totalQuantitySelected}
      </Col>
      <Col
        span={2}
        style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
      ></Col>
      <Col
        span={2}
        style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
      ></Col>
      <Col
        span={2}
        style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
      ></Col>
      <Col
        span={2}
        style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}
      >
        {flybyUtils.numberFormat(
          props.wishlistTotal.totalAmountExclTaxesSelected,
          2
        )}
      </Col>
      <Col span={1} style={{ paddingLeft: 5, textAlign: "right" }}></Col>
    </Row>
  );
};

export const SalesOrderPreviewHeader = (props: {
  parameters: DefaultParameter;
}) => {
  return (
    <Row style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
      <Col span={3} style={{}}>
        Référence
      </Col>
      <Col span={4} style={{ paddingLeft: 5 }}>
        Libellé
      </Col>
      <Col span={3} style={{ paddingLeft: 5 }}>
        {props.parameters.SHOW_MANUFACTURER ? "Fabricant" : ""}
      </Col>
      <Col span={3} style={{ paddingLeft: 5 }}>
        Délai
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Quantité
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Prix tarif € HT
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Remise %
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Prix net € HT
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        Montant HT
      </Col>
      <Col span={1} style={{ paddingLeft: 5, textAlign: "right" }}></Col>
    </Row>
  );
};

export const SalesOrderPreviewAddress = (props: {
  salesOrder: ISalesOrder;
}) => {
  return (
    <div
      style={{
        fontSize: "0.95em",
        border: "solid",
        borderColor: "#e8e8e8",
        borderWidth: "1px",
        marginBottom: 5,
      }}
    >
      <Row style={{ padding: 5 }}>
        <Col span={11}>
          <Divider orientation="left">Adresse de livraison</Divider>
          {props.salesOrder.deliveryCompanyName}
          <br />
          {props.salesOrder.deliveryAddress1}
          <br />
          {props.salesOrder.deliveryAddress2}
          <br />
          {props.salesOrder.deliveryZipCode}
          <br />
          {props.salesOrder.deliveryCity}
          <br />
        </Col>
        <Col span={2}></Col>
        <Col span={11}>
          <Divider orientation="left">Adresse de facturation</Divider>
          {props.salesOrder.billingCompanyName}
          <br />
          {props.salesOrder.billingAddress1}
          <br />
          {props.salesOrder.billingAddress2}
          <br />
          {props.salesOrder.billingZipCode}
          <br />
          {props.salesOrder.billingCity}
          <br />
        </Col>
      </Row>
    </div>
  );
};
